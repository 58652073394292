import * as React from 'react'
import Joi, { boolean } from 'joi'
import { t } from 'i18next'
import { useHistory } from 'react-router-dom'
import 'react-phone-number-input/style.css'
import auth_ from '../sass/auth.module.sass'
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material'
import {
  checkEmailVerify,
  fetchPhoneCode,
  fetchProviderUser,
  flushState,
  sendEmailVerification,
  setRegisterInputEmpty,
  setRegisterStep,
  signupByEmail,
  signupByProvider,
} from '../store/auth/actionCreator'
import { useDispatch, useSelector } from '../store'
import { Page } from '../component/auth/Page'
import { getCryptLocalStorage, setCryptLocalStorage } from '../function/storage'
import { LocalStorage } from '../enum/storage'
import { Provider } from '../enum/provider'
import { useSocialStyles } from '../style/auth'
import { InputPassword } from '../component/InputPassword'
import { FormVerifyPhone } from '../component/FormVerifyPhone'
import { RegisterStepper } from '../container/auth/RegisterStepper'
import sprite_ from '../sass/sprite.module.sass'
import { AuthStep, AuthStorage } from '../store/auth/reducer'
import { getAuthState } from '../store/auth/selector'
import { E164Number, InputPhone } from '../component/InputPhone'
import {
  ErrorsState,
  preventCyrillicOnInput,
  preventEmptySpaceOnInput,
  validateForm,
  notRequired,
  email,
  password,
  phone,
  onlyLatinLetters,
} from '../function/validation'
import { FormError } from '../component/FormError'
import { clearFieldError, fetchBackendAlive } from '../store/common/actionCreator'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog, { DialogProps } from '@mui/material/Dialog'

import { checkEmailBlacklist, checkPhoneBlacklist } from '../store/blacklist/actionCreator'
import Cookies from 'cookies-js'
import { CropLandscapeOutlined } from '@mui/icons-material'

const termsOfUseConfig = {
  subStringAppName : process.env.REACT_APP_NAME,
  subStringBotName : process.env.REACT_APP_BOT_NAME,
  subStringEmail : process.env.REACT_APP_EMAIL,
}

const notRequiredInputs = {
  type: notRequired,
  phoneCode: notRequired,
  signInProvider: notRequired,
  photoURL: notRequired,
}

const schemaProvider = Joi.object({
  ...notRequiredInputs,
  firstname: onlyLatinLetters.label(t('field.label.firstname')),
  lastname: onlyLatinLetters.label(t('field.label.lastname')),
  email: email,
  password: notRequired,
  phone: notRequired,
})
const schemaMail = Joi.object({
  ...notRequiredInputs,
  firstname: onlyLatinLetters.label(t('field.label.firstname')),
  lastname: onlyLatinLetters.label(t('field.label.lastname')),
  email: email,
  password: password,
  phone: notRequired,
})
const schemaPhone = Joi.object({
  ...notRequiredInputs,
  firstname: notRequired.label(t('field.label.firstname')),
  lastname: notRequired.label(t('field.label.lastname')),
  email: notRequired,
  password: notRequired,
  phone: phone,
})

const agreeingStyle = { fontSize: 16, color: '#000' }
const agreeingLinkStyle = { ...agreeingStyle, textDecorationColor: '#000' }

function getCookie(name : any) {
  const dc = document.cookie
  let end : any  
  const prefix = name + "="
  let begin = dc.indexOf("; " + prefix)
  if (begin == -1) {
      begin = dc.indexOf(prefix)
      if (begin != 0) return null 
  }
  else
  {
      begin += 2
      end = document.cookie.indexOf(";", begin)
      if (end == -1) {
      end = dc.length
      }
  }
  return decodeURI(dc.substring(begin + prefix.length, end))
} 

export function RegisterPage() {
  
  const gaClientId = getCookie('gaclientid') === null ? "Unknown" : Cookies.get('gaclientid')
  const handlCustom1 = getCookie('handl_custom1') === null ? "Unknown" : Cookies.get('handl_custom1')
  const handlIP = getCookie('handl_ip') === null ? "Unknown" : Cookies.get('handl_ip')
  const utmCampaign = getCookie('utm_campaign') === null ? "Unknown" : Cookies.get('utm_campaign')
  const utmContent = getCookie('utm_content') === null ? "Unknown" : Cookies.get('utm_content')
  const utmMedium = getCookie('utm_medium') === null ? "Unknown" : Cookies.get('utm_medium')
  const utmSource = getCookie('utm_source') === null ? "Unknown" : Cookies.get('utm_source')
  const utmTerm = getCookie('utm_term') === null ? "Unknown" : Cookies.get('utm_term')  
  
 // Render register form if back-end is deployed
 const { backendAlive } = useSelector((state) => state.backendAlive)
 React.useEffect(() => {
  dispatch(fetchBackendAlive)
 }, [])

  const [open, setOpen] = React.useState(false)
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper')

  const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
    setOpen(true)
    setScroll(scrollType)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const descriptionElementRef = React.useRef<HTMLElement>(null)
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  const [checked, setChecked] = React.useState([true, false])

  const dispatch = useDispatch()
  const history = useHistory()
  const socialClasses = useSocialStyles()

  const storage: AuthStorage =
    getCryptLocalStorage(LocalStorage.SIGNUP_STATE) || {}

  const { fieldErrors } = useSelector((state) => state.common)
  const { registerStep, registerInput, userCredential, authPhoneVerify } =
    useSelector(getAuthState)

  const resetState = React.useCallback(() => {
    localStorage.removeItem(LocalStorage.SIGNUP_STATE)

    dispatch(flushState)
    dispatch(setRegisterStep('EMAIL_FORM'))
    dispatch(setRegisterInputEmpty)
    setStepActive(0)
  }, [dispatch])

  const handleNextStep = React.useCallback(() => {
    let newSkipped = stepSkipped

    if (stepIsSkipped(stepActive)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(stepActive)
    }

    setStepActive((prevActiveStep) => prevActiveStep + 1)
    setStepSkipped(newSkipped)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateInputData = React.useCallback(() => {
    if (registerStep === 'INPUT_EMAIL_PHONE') {
      handleNextStep()
    }
  }, [registerStep, handleNextStep])

  const prepareVerifyPhoneState = React.useCallback(() => {
    const storage: AuthStorage = getCryptLocalStorage(LocalStorage.SIGNUP_STATE)

    if (registerStep === 'INPUT_PROVIDER_PHONE') {
      const data: AuthStorage = { ...storage, type: 'VERIFY_PROVIDER_PHONE' }
      setCryptLocalStorage(LocalStorage.SIGNUP_STATE, data)
      dispatch(setRegisterStep('VERIFY_PROVIDER_PHONE'))
    }

    if (registerStep === 'INPUT_EMAIL_PHONE') {
      const data: AuthStorage = { ...storage, type: 'VERIFY_EMAIL_PHONE' }
      setCryptLocalStorage(LocalStorage.SIGNUP_STATE, data)
      dispatch(setRegisterStep('VERIFY_EMAIL_PHONE'))
    }
  }, [dispatch, registerStep])

  React.useEffect(() => {
    const steps: AuthStep[] = [
      'PROVIDER_FORM',
      'INPUT_PROVIDER_PHONE',
      'VERIFY_PROVIDER_PHONE',
      'VERIFY_EMAIL_PHONE',
      'INPUT_EMAIL_PHONE',
    ]
    const isMustHaveFirebaseObjects = steps.includes(registerStep)

    if (isMustHaveFirebaseObjects && userCredential === null) {
      resetState()
    }
  }, [userCredential, registerStep, dispatch, resetState])

  React.useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://www.googleoptimize.com/optimize.js?id=OPT-KCN82CK"
    document.body.appendChild(script)
    return () => {
      // clean up the script when the component in unmounted
      document.body.removeChild(script)
    }
  }, [])

  React.useEffect(() => {
    setFirstname(registerInput.firstname)
    setLastname(registerInput.lastname)
    setEmail(registerInput.email)
    setPassword(registerInput.password)
    setPhone(registerInput.phone)
    setPhoneCode(registerInput.phoneCode || '')
   }, [registerInput])

  React.useEffect(() => {
    if (userCredential) {
      updateInputData()
    }
  }, [userCredential, updateInputData])

  React.useEffect(() => {
    if (authPhoneVerify) {
      prepareVerifyPhoneState()
    }
  }, [authPhoneVerify, prepareVerifyPhoneState])

  const stepActiveInitial = React.useMemo(() => {
    const steps: AuthStep[] = [
      'INPUT_PROVIDER_PHONE',
      'VERIFY_PROVIDER_PHONE',
      'INPUT_EMAIL_PHONE',
      'VERIFY_EMAIL_PHONE',
    ]

    if (steps.includes(registerStep)) return 1
    else return 0
  }, [registerStep])

  const [firstname, setFirstname] = React.useState(
    registerInput?.firstname || '',
  )
  const [lastname, setLastname] = React.useState(registerInput?.lastname || '')
  const [email, setEmail] = React.useState(registerInput?.email || '')
  const [password, setPassword] = React.useState(registerInput?.password || '')
  const [phone, setPhone] = React.useState<E164Number | undefined>(
    registerInput?.phone || undefined,
  )
  const [phoneCode, setPhoneCode] = React.useState(
    registerInput?.phoneCode || '',
  )
  const [errors, setErrors] = React.useState<ErrorsState>(null)

  const [checkedTermsPolicy, setCheckedTermsPolicy] = React.useState(registerStep === 'EMAIL_FORM')
    const checkTermsPolicy = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedTermsPolicy(current => !current)
  }
  const [stepActive, setStepActive] = React.useState(stepActiveInitial)
  const [stepSkipped, setStepSkipped] = React.useState(new Set<number>())
  const stepIsSkipped = React.useCallback(
    (step: number) => {
      return stepSkipped.has(step)
    },
    [stepSkipped],
  )

  const requestData = {
    firstname: firstname.trim(),
    lastname: lastname.trim(),
    email,
    password,
    phone,
    phoneCode,
  }

  const handleProvider = (provider: Provider) => {
    dispatch(fetchProviderUser(provider))
  }

  const handleSubmitStep1 = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    dispatch(clearFieldError)

    if (registerStep === 'PROVIDER_FORM') {

      const velidateEmailCallback = (isBlacklisted: boolean) =>{
        if (isBlacklisted) {
          requestData.email = 'fail_email'
          const error = validateForm(schemaMail, requestData)
          if (error) { 
            setErrors(error) 
          }
        }
        const error = validateForm(schemaProvider, requestData)
        if (error) setErrors(error)
        else {
          setErrors(null)

          const storage: AuthStorage = getCryptLocalStorage(
            LocalStorage.SIGNUP_STATE,
          )
          const newStorage: AuthStorage = {
            type: 'INPUT_PROVIDER_PHONE',
            firstname: firstname,
            lastname: lastname,
            utmInfo: {
              gaClientId: gaClientId || "",
              handlCustom1: handlCustom1 || "",
              handlIP : handlIP || "",
              utmCampaign : utmCampaign || "", 
              utmContent : utmContent || "",
              utmMedium: utmMedium || "",
              utmSource: utmSource || "",
              utmTerm: utmTerm || "",
              isIpPhoneMatches: true,
            },
          }
          setCryptLocalStorage(LocalStorage.SIGNUP_STATE, {
            ...storage,
            ...newStorage,
          })

          dispatch(setRegisterStep('INPUT_PROVIDER_PHONE'))
          handleNextStep()
        }
      }
      
      dispatch(checkEmailBlacklist(email, handlIP, velidateEmailCallback))
    }

    if (registerStep === 'EMAIL_FORM') {
      const velidateEmailCallback = (isBlacklisted: boolean) =>{
        if (isBlacklisted) {
          requestData.email = 'fail_email'
          const error = validateForm(schemaMail, requestData)
          if (error) { 
            setErrors(error) 
          }
        }
        const error = validateForm(schemaMail, requestData)
        if (error) { 
          setErrors(error) 
        }
        else {
          const storage: AuthStorage = {
            type: 'EMAIL_FORM',
            firstname: firstname,
            lastname: lastname,
            password: password,
            email: email,
            utmInfo: {
              gaClientId: gaClientId || "",
              handlCustom1: handlCustom1 || "",
              handlIP : handlIP || "",
              utmCampaign : utmCampaign || "", 
              utmContent : utmContent || "",
              utmMedium: utmMedium || "",
              utmSource: utmSource || "",
              utmTerm: utmTerm || "",
              isIpPhoneMatches: true,
            },
          }
          setCryptLocalStorage(LocalStorage.SIGNUP_STATE, storage)

          dispatch(sendEmailVerification(email.toLowerCase(), password))
        }
      }
      dispatch(checkEmailBlacklist(email, handlIP, velidateEmailCallback))
    }

    if (registerStep === 'CHECK_EMAIL') {
      const storage = getCryptLocalStorage(LocalStorage.SIGNUP_STATE)

      if (storage === null) {
        history.push('/auth')
        return
      }
      dispatch(checkEmailVerify(storage.email, storage.password))
    }
  }

  const handleSubmitStep2 = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    dispatch(clearFieldError)

    if (
      registerStep === 'INPUT_PROVIDER_PHONE' ||
      registerStep === 'INPUT_EMAIL_PHONE'
    ) {
      const validatePhoneCallback = (isBlacklisted: boolean) => {
        if (isBlacklisted) {
          requestData.phone = 'fail_phone'
        }
        const error = validateForm(schemaPhone, requestData)
        if (error) setErrors(error)
        else {
          setErrors(null)
          const storage: AuthStorage = getCryptLocalStorage(
            LocalStorage.SIGNUP_STATE,
          )
          const newStorage: AuthStorage = { ...storage, phone: phone }
          setCryptLocalStorage(LocalStorage.SIGNUP_STATE, newStorage)
  
          dispatch(fetchPhoneCode(String(phone), 'register'))
        }
      }
      
      if (phone) dispatch(checkPhoneBlacklist(phone, validatePhoneCallback))
    }
    if (registerStep === 'VERIFY_PROVIDER_PHONE') {
      
      const error = validateForm(schemaPhone, requestData)

      if (error) setErrors(error)
      else {
        setErrors(null)
        dispatch(signupByProvider(phoneCode, storage.provider as Provider))
      }
    }

    if (registerStep === 'VERIFY_EMAIL_PHONE') {
      dispatch(signupByEmail(phoneCode))
    }
  }

  const handleBack = () => {
    resetState()
    history.push('/auth')
  }

  React.useEffect(() => {
    setErrors(null)
    dispatch(clearFieldError)
  }, [firstname, lastname, email, password, phone, dispatch])

  const usageAgreementContent = (msgString : any) => {
    const subStringAppName = termsOfUseConfig.subStringAppName
    const subStringBotName = termsOfUseConfig.subStringBotName
    const subStringEmail = termsOfUseConfig.subStringEmail
    return msgString
      .replace("&_espy_search_name", subStringAppName)
      .replace("&_espy_bot_name",subStringBotName)
      .replace("&_email",subStringEmail)
  }

  return (
    <Page>
      <Grid className={auth_.title} item>
        {t('auth.register.title')}
      </Grid>
      {backendAlive ? (
        <>
        <Grid item mt={3}>
          <Typography variant="semiBold">{t('auth.register.hint')}</Typography>
        </Grid>
        {(registerStep === 'PROVIDER_FORM' || registerStep === 'EMAIL_FORM') && (
          <>
            <Grid item my={4}>
              <Typography variant="semiBold" color="secondary">
                {t('auth.register.social')}
              </Typography>

                  <Stack direction="row" spacing={3} mt={3}>
                    <Button
                      classes={socialClasses}
                      className={socialClasses.google}
                      onClick={() => handleProvider(Provider.GOOGLE)}
                    >
                      <Box className={sprite_.social__large_googleProvider} />
                    </Button>
                    <Button
                      classes={socialClasses}
                      className={socialClasses.facebook}
                      onClick={() => handleProvider(Provider.FACEBOOK)}
                    >
                      <Box className={sprite_.social__large_fbProvider} />
                    </Button>
                    <Button
                      style={{ display: 'none' }}
                      classes={socialClasses}
                      className={socialClasses.microsoft}
                      onClick={() => handleProvider(Provider.MICROSOFT)}
                    >
                      <Box className={sprite_.social__large_microsoftProvider} />
                    </Button>
                  </Stack>
                </Grid>

                <Grid item>
                  <Box className={auth_.or}>
                    <hr />
                    <span>{t('auth.or')}</span>
                    <hr />
                  </Box>
                </Grid>
              </>
              )
          }

        <Grid item my={4}>
          <RegisterStepper
            stepIsSkipped={stepIsSkipped}
            stepActive={stepActive}
          />
        </Grid>

        <Grid item>
          {stepActive === 0 && (
            <form onSubmit={handleSubmitStep1} noValidate>
              <Stack spacing={3}>
                <Typography variant="bold" fontSize={26}>
                  {t('auth.steps.titleMail')}
                </Typography>

                {(registerStep === 'PROVIDER_FORM' ||
                  registerStep === 'EMAIL_FORM') && (
                  <>
                    <Stack direction="row" spacing={2}>
                      <FormControl variant="outlined">
                        <FormLabel required>
                          {t('field.label.firstname')}
                        </FormLabel>
                        <OutlinedInput
                          name="firstname"
                          value={firstname}
                          error={Boolean(errors?.firstname)}
                          onChange={(e) =>
                            preventCyrillicOnInput(e, setFirstname)
                          }
                          placeholder={t('field.hint.firstname')}
                          inputProps={{
                            maxLength: 50,
                          }}
                        />
                        <FormError error={errors?.firstname} />
                      </FormControl>
                      <FormControl>
                        <FormLabel required>
                          {t('field.label.lastname')}
                        </FormLabel>
                        <OutlinedInput
                          name="lastname"
                          value={lastname}
                          error={Boolean(errors?.lastname)}
                          onChange={(e) => preventCyrillicOnInput(e, setLastname)}
                          placeholder={t('field.hint.lastname')}
                          inputProps={{
                            maxLength: 50,
                          }}
                        />
                        <FormError error={errors?.lastname} />
                      </FormControl>
                    </Stack>

                    <FormControl>
                      <FormLabel required>{t('field.label.email')}</FormLabel>
                      <OutlinedInput
                        name="email"
                        value={email}
                        error={Boolean(errors?.email)}
                        onChange={(e) => preventEmptySpaceOnInput(e, setEmail)}
                        placeholder={t('field.hint.email')}
                        readOnly={registerStep === 'PROVIDER_FORM'}
                        autoComplete="off"
                      />
                      <FormError
                        error={fieldErrors.registerEmail || errors?.email}
                      />
                    
                    {registerStep !== 'EMAIL_FORM' && (
                    <p>
                    <FormControlLabel
                    label={t('field.checkBoxTermsOfUse.checkBoxCaption').toString()}
                    control={
                    <Checkbox onChange={checkTermsPolicy}/>
                    }
                    />
                    </p>
                    )}
                    </FormControl>
                  </>
                )}

                {registerStep === 'EMAIL_FORM' && (
                  <>
                  <InputPassword
                    label={t('field.label.password')}
                    value={password}
                    error={errors?.password}
                    onChange={setPassword}
                    placeholder={t('field.hint.password')}
                    required
                  />

                  <FormControlLabel
                    label={t('field.checkBoxTermsOfUse.checkBoxCaption').toString()}
                    control={
                    <Checkbox onChange={checkTermsPolicy}/>
                    }
                    />
                    </>
                )}

                {registerStep === 'CHECK_EMAIL' && (
                  <Typography fontSize={16}>
                    <span>
                      {t('auth.register.check1')}{' '}
                      <Typography variant="semiBold" color="primary">
                        {storage.email}
                      </Typography>{' '}
                      {t('auth.register.check2')}
                      <Typography variant="bold" fontSize={16}>
                      {t('auth.register.check3')}
                      </Typography>{' '}
                    </span>
                  </Typography>
                )}
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  disabled={checkedTermsPolicy}
                >
                  {t('common.next')}
                </Button>
              </Stack>
            </form>
          )}

          {stepActive === 1 && (
            <form onSubmit={handleSubmitStep2} noValidate>
              <Stack spacing={3}>
                <Typography variant="bold" fontSize={26}>
                  {t('auth.steps.titlePhone')}
                </Typography>

                {(registerStep === 'INPUT_PROVIDER_PHONE' ||
                  registerStep === 'INPUT_EMAIL_PHONE') && (
                  <>
                    <FormControl>
                      <FormLabel required>{t('field.label.phone')}</FormLabel>
                      <InputPhone
                        value={phone}
                        error={
                          fieldErrors?.phone ||
                          fieldErrors.registerPhone ||
                          errors?.phone
                        }
                        onInput={setPhone}
                      />
                      <FormError
                        error={
                          fieldErrors.registerPhone ||
                          fieldErrors.phone ||
                          errors?.phone
                        }
                      />
                    </FormControl>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      color="primary"                   
                      fullWidth                    
                    >
                      {t('common.next')}
                    </Button>
                  </>
                )}
                {(registerStep === 'VERIFY_PROVIDER_PHONE' ||
                  registerStep === 'VERIFY_EMAIL_PHONE') && (
                  <FormVerifyPhone
                    phone={phone}
                    phoneCode={phoneCode}
                    setPhoneCode={setPhoneCode}
                    setStepActive={setStepActive}
                    registerStep={registerStep}
                  />
                )}
              </Stack>
            </form>
          )}
        </Grid>

        <Grid item mt={2}>
          <Grid container direction="column" alignItems="center">
            <Grid item style={{ textAlign: 'center' }}>
            <Typography style={agreeingStyle}>
                <span>{t('auth.register.licence.text')} </span>
                <span>{t('auth.register.licence.privacy')} {t('common.and')} {t('auth.register.licence.terms')} </span>
                {/* <Link
                  variant="underlined"
                  target="_blank"
                  style={agreeingLinkStyle}
                  onClick={handleClickOpen('paper')}
                >
                  {t('auth.register.licence.privacy')} {t('common.and')} {t('auth.register.licence.terms')}
                </Link> */}
                .
              </Typography>
            </Grid>
            <Grid item mt={3}>
              <Button onClick={handleBack}>{t('auth.register.back')}</Button>
            </Grid>
          </Grid>
        </Grid>

        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">TERMS OF SERVICE AND PRIVACY POLICY</DialogTitle>
          <DialogContent dividers>
          <Container fixed>
          <Typography style={{textDecoration: 'underline', fontWeight: 'bold', display: 'inline-block'} }> {"Disclaimer for IRBIS Search Portal and Services".toUpperCase()} </Typography>
          <Typography style={{display: 'inline-block'}}> 
          Welcome to the IRBIS Search Portal. By using our services, you agree to the following terms and conditions:
          </Typography>
          <Typography> 
              <List>
              <Box>1. <b>Account Registration:</b> It is mandatory to sign up with genuine and accurate information. Use of fake names, email addresses, or phone numbers is strictly prohibited. Subscribers identified with fake identities will be blocked and barred from future use of our portal.</Box>
              <Box>2. <b>Subscription Packages:</b> To access the portal, subscribers must purchase a subscription package. Each package grants one-month access to the portal. Upon purchase, the subscriber's balance is credited with funds equivalent to the package price.</Box>
              <Box>3. <b>Usage of Search Queries:</b> Each search query incurs a cost based on the type of package purchased. Once the balance is depleted, subscribers cannot run additional queries but can view results of previous searches. To conduct more searches, an additional package with quotas must be purchased.</Box>
              <Box>4. <b>Non-Refundable Policy:</b> If a subscriber does not utilize the search queries within the one-month subscription period, the remaining balance will be forfeited without a refund.</Box>
              <Box>5. <b>IRBIS Pro Subscription:</b> The "IRBIS Pro" package offers access to the IRBIS Pro environment for one month, including a basic number of search quotas. Subscribers can buy Refill packages for additional quotas.</Box>
              <Box>6. <b>Refill Packages and Quota Validity:</b> If an IRBIS Pro subscription expires, access to the IRBIS Pro environment is lost, but purchased refill quotas remain intact. Renewing the IRBIS Pro subscription restores access to the environment along with any unused quotas.</Box>
              <Box>7. <b>Search Quota Rate:</b> The standard search quota rate is 2 searches per minute. This rate can be customized only for users with a Service Level Agreement (SLA).</Box>
              <Box>8. <b>Pricing and Changes:</b> Prices for standard packages and quotas are subject to change without prior notice. However, subscribers with auto-renewal enabled will continue at their original pricing even if new prices are introduced.</Box>
              <Box>9. <b>Auto-Renewal:</b> Subscribers with auto-renewal will retain their package at the old pricing, even if new prices are implemented.</Box>
              </List>
          </Typography>
          <Typography style={{display: 'inline-block'}}> 
          By using the IRBIS Search Portal and its services, you acknowledge and agree to these terms. We reserve the right to modify these terms at any time, and continued use of our services constitutes acceptance of such changes.
          </Typography>

          <Typography style={{textDecoration: 'underline', fontWeight: 'bold', display: 'inline-block'}}> TERMS OF SERVICE </Typography>
          <Typography style={{display: 'inline-block'}}> 
            CLOUDCODE (the <b>"Company"</b>) provides you (the <b>"Client"</b>) with, and the Client 
            accepts, a limited right to access and use the Company's SaaS system named "IRBIS" 
            (the <b>"System"</b>), at the Company's website <a href='http://www.cloudcode.pro/'>www.cloudcode.pro</a> (the "Site"), pursuant and 
            subject to these Terms of Use (these <b>"Terms"</b>), the additional terms and conditions set 
            forth in the subscription form, under which the Client subscribes to the System the 
            <b>"Subscription Form"</b>) and the Privacy Policy (these Terms, the Subscription Form and 
            the Privacy Policy, together, the <b>"Agreement"</b>). By subscribing for, accessing, and/or 
            using, the System and/or the Site, the Client agreed to the terms and conditions of these
            Terms and the Agreement.
          </Typography>
          <Typography> 
              <List>
              <Box>1. <b>Definitions.</b> In these Terms, each of the following capitalized terms shall have the
              meaning set forth next to it.</Box>
              <Box>1.1 <b>"Content"</b> means any information, data, text, software, sound, photograph, 
              graphic, video, message and/or other material and/or content.</Box>
              <Box>1.2  The <b>"Documentation"</b> means the System technical and/or operational 
              documentation provided to the Client by the Company, as updated by the 
              Company from time to time; the Documentation shall be deemed part of the 
              System.</Box>
              <Box>1.3  The <b>"Client Content"</b> mean Content uploaded, posted, provided, generated, 
              transmitted and/or stored by the Client and/or anyone on its behalf, through and/or
              in connection with the Client`s use of the System and/or the Site.</Box>
              <Box>1.4  The <b>"Term"</b> means the term set forth in the Subscription Form, for which the 
              Client actually pays to the Company pursuant to the Agreement, unless such term 
              is terminated earlier pursuant to Section 10 below.</Box>
              <Box>1.5  The <b>"Privacy Policy"</b> means the Company privacy and cookies policies, as 
              updated by the Company from time to time.</Box>
              <Box>1.6  The <b>"Subscription Form"</b> means the subscription form, under which the 
              Client subscribed to the System.</Box>
              <Box>1.7  The <b>"Open Source Terms"</b> means all terms and/or conditions of use as well 
              as all other agreements in connection with open source software, shareware and/or
              the like, including, without limitation, the terms set.</Box>
              <Box>2. <b>License</b></Box>
              <Box>2.1 <b>Schedule.</b> The time schedule for the delivery of access to the System to the 
              Client shall be as set forth in the Subscription Form.</Box>
              <Box>2.2  <b>Right to Use.</b> Subject to all the terms, conditions, limitations, and restrictions
              set forth in the Agreement, the Company grants the Client a non-exclusive 
              revocable non-transferable not sub-licensable royalty bearing limited right solely 
              to access and use the System, during the Term only. The Client shall use the 
              System and the Site in accordance with the Documentation and the Company's 
              instructions, as may be updated by the Company from time to time, and in a 
              reasonable and proper manner. Other than the right to use the System as explicitly
              permitted thereto in the Agreement, the Client shall have no rights in connection 
              with the System and/or the Site.</Box>
              <Box>2.3  <b>Title.</b> The Client acknowledges that the Company is and shall remain the sole
              owner of all rights, title and interests, including, without limitation, any patents, 
              copyrights, trademarks, trade names, service names, trade secrets, goodwill and 
              other intellectual property and/or like rights, in, to and/or in connection with the 
              System and/or the Site as well as any changes, developments, improvements, 
              versions, variations and/or derivatives of any of the foregoing.</Box>
              <Box>2.4  <b>Various Restrictions.</b> The Client shall not, directly or indirectly, (i) provide 
              or allow access to, give, display, disclose, reproduce, duplicate, copy, sell, resell, 
              license, sublicense, assign, transfer, lend, lease, share and/or dispose of the 
              System and/or the Site in any manner whatsoever; (ii) change, develop, modify, 
              reverse engineer, disassemble, decompile, deconstruct, reduce to human readable 
              form, translate and/or make any derivatives of the System and/or the Site in any 
              way and/or by any means; (iii) combine, incorporate and/or integrate the System 
              and/or the Site with any other system, software, technology, site or item; (iv) hack
              and/or otherwise engage in a manner that may be detrimental to the System and/or
              the Site; (v) breach the security of the System and/or the Site and/or engage in 
              identifying security vulnerabilities of any of the foregoing; (vi) interfere with, 
              circumvent, manipulate, impair and/or disrupt the System and/or the Site and/or 
              the operation and/or functionality of the System and/or the Site; (vii) work around
              and/or circumvent any technical limitations in the System and/or the Site; (viii) 
              use any tool or otherwise act to enable features and/or functionalities that are 
              otherwise disabled and/or inaccessible; (ix) use the System and/or the Site for any
              abusive, fraudulent and/or illegal activity; and/or (x) remove any notices, marks 
              and/or logos the System and/or the Site and/or use any name, mark and/or logo 
              that is identical or resembles the names, marks and/or logos used by the 
              Company. If you are an individual, You hereby confirm that You are above the 
              age of 18.</Box>
              <Box>2.5  <b>Compliance.</b> The Client shall comply with all applicable law, including, 
              without limitation, any export and/or import control law, in connection with the 
              access to and/or use of the System, the Site and/or any Content. Without 
              limitation of the foregoing, the Client shall comply with all applicable law related 
              to the import and/or export of the System and/or any Client Content and/or any 
              other Content.</Box>
              <Box>2.6  <b>Viruses.</b> The Client undertakes that all equipment, hardware, devises, 
              software, applications, websites and other items thereof and/or used thereby shall 
              not (i) contain any viruses, Trojan horses, worms and/or any other harmful 
              component; (ii) disable, override and/or otherwise interfere with the System 
              and/or the Site alerts, warnings, display panels, consent panels, check box and/or 
              the like; and/or (iii) facilitate or permit any disabling, hacking, circumventing 
              and/or interference with any security and/or privacy mechanism of the System 
              and/or the Site. The Client alone shall bear all responsibility and liability in 
              connection with the all equipment, hardware, devises, software, applications, sites
              and other items thereof and/or used thereby.</Box>
              <Box>2.7  <b>Feedback License.</b> The Client grants the Company a perpetual, irrevocable, 
              worldwide, fully transferable, sub-licensable, fully paid-up, royalty free, license to
              use and/or exploit in any manner any and all feedbacks, suggestions and ideas of 
              and/or provided by the Client in connection with the System and/or the Site.</Box>
              <Box>2.8  <b>Communication Providers.</b> The Client's access to the System and/or the Site
              shall be subject to their engagement of telecommunications providers, the terms 
              and conditions of its agreements therewith and the rates and fees of such 
              providers, all of which are the sole responsibility of the Client.</Box>
              <Box>2.9  <b>Open Source.</b> The System and/or the Site may contain open source software,
              shareware and/or the like. The use of such software is subject, in addition to the 
              Agreement, to the Open Source Terms. In the event of any inconsistency with 
              respect to such open source software, shareware and/or the like between the 
              Agreement and such Open Source Terms, the latter shall prevail. Notwithstanding
              any other provision, the Company provides no representations, warranties, 
              undertakings and/or indemnities in connection with any open source software, 
              shareware and/or the like and shall have no liability whatsoever in connection 
              therewith.</Box>
              <Box>2.10  <b>System Changes.</b> The Company shall be entitled, at any time, from time to 
              time and at its sole discretion, make changes to, modify, develop, suspend, and/or 
              discontinue, the System, the Site, any feature and/or functionality of any of the 
              foregoing and/or the operation of any of the foregoing, in each case – whether in 
              whole and/or in part. The Client shall have no claims and/or demands in 
              connection with any of the foregoing. The Client acknowledges that the System 
              and/or the Site may contain devices, codes, instructions and/or feature capable of 
              accessing, modifying and/or disabling the System and/or the Site and/or any 
              portion of any of the foregoing.</Box>
              <Box>2.11  <b>Third Party Items.</b> The System and/or the Site may display, include, refer 
              to, make available, or contain links to, third party Content, applications, websites, 
              systems, products, services and/or items <b>("Third Party Items")</b>. The Client 
              acknowledges and agrees that the Company shall not be responsible for, and shall 
              have no liability in connection with any Third Party Items, including, without 
              limitation, their accuracy, completeness, timeliness, validity, copyright 
              compliance, legality, decency and/or quality and/or any other aspect of Third 
              Party Items. Any access and use of Third Party Items is entirely at the Client's 
              own risk and subject to such third parties' respective terms, conditions, policies 
              and guidelines.</Box>
              <Box>3. <b>Content</b></Box>
              <Box>3.1 <b>Responsibility.</b> The Client shall be fully responsible for all of the Client 
              Content; the Company has no responsibility for the Client Content; the Company 
              does not undertake to control the Client Content and does not have any obligation 
              to monitor such Client Content for any purpose; the Client is solely responsible 
              for any Client Content that may be lost and/or unrecoverable through its use of the
              System and/or the Site.</Box>
              <Box>3.2  <b>Content License.</b> The Client grants the Company a perpetual, irrevocable, 
              worldwide, fully transferable, sub-licensable, non-revocable, fully paid-up, 
              royalty free license to host and use the Client Content and/or any information 
              related to the Client's access and/or use of the System and/or the Site in order to 
              provide and/or improve the System and/or the Site for the Client, otherwise in 
              connection with the Agreement, develop the System, develop other products, 
              systems and/or services, analyze trends and/or conduct marketing and/or 
              promotional activities. Notwithstanding any other provision, the Company may 
              disclose any information, including, without limitation, any Client Content, 
              necessary or appropriate, in the Company's discretion, to satisfy its legal duties, 
              protect the System and/or the Site, the Company's clients, customers, distributors, 
              resellers and/or end users and/or operate the System properly.</Box>
              <Box>3.3  <b>Content Restrictions.</b> The Client shall not use the System to upload, 
              reproduce, engage in, or transmit, any of the following: (i) illegal, fraudulent, 
              deceptive, misleading, libelous, defamatory, obscene, pornographic, profane, 
              threatening, abusive, hateful, harassing, offensive, inappropriate and/or 
              objectionable information or communications of any kind;  (ii) Content that 
              would impersonate someone else and/or may falsely represent Client's identity or 
              qualifications and/or that constitutes a breach of any individual's privacy; (iii) 
              information which involves fraud, embezzlement, money laundering, insider 
              trading, support for terrorism or any other activity prohibited by law; (iv) virus, 
              Trojan horse, worm or other disruptive or harmful software or data; and/or (v) any
              Content which is not legally the Client's without due permission from the 
              applicable owner. In the event that the Company suspects any of the foregoing, 
              the Company may erase the Client Content or any portion thereof and/or report 
              the Client to law enforcement authorities. The Client is subject to limitations on 
              storage, quantity, size and/or format of the Content permitted for upload to or 
              through the System, as set forth in the Commercial Document.</Box>
              <Box>3.4  <b>Content Monitoring.</b> The System and/or the Site do not necessarily perform 
              any virus-checking or other scanning for harmful code. The Company may, but 
              has no obligation to, monitor any Content and/or engage technological measures 
              to detect and prevent use of the System and/or the Site not in compliance with the 
              Agreement. The Company, in its sole discretion, may remove, or refuse to 
              remove, any Content, in whole or in part, which the Company believes to be 
              unacceptable, undesirable, inappropriate and/or in violation of the Agreement.</Box>
              <Box>4. <b>Liability</b></Box>
              <Box>4.1 <b>Indirect Damages.</b>  The Company shall not be liable for incidental, indirect, 
              special, consequential, punitive and/or exemplary damages, including, but not 
              limited to, loss of profits, loss of revenues, loss of investment, loss of business, 
              loss, damage, security, corruption and/or theft of and/or to data and/or 
              information, loss of use and/or damage to reputation, whether in tort, contract 
              and/or otherwise, whether or not the Company is made aware of the possibility of 
              such damages.</Box>
              <Box>4.2  <b>Cap.</b> In no event will the Company's aggregate liability, whether in tort, 
              contract and/or otherwise, under and/or in connection with an access package the 
              Agreement, exceed 10% of the total amount of fees actually received thereby for 
              such package pursuant to the Agreement (excluding any VAT and/or sales taxes).</Box>
              <Box>4.3  <b>Force Majeure.</b>    The Company shall have no liability for any failure not 
              within its reasonable control, such as labor disputes, nature disasters, pandemics, 
              and accidents.</Box>
              <Box>4.4  <b>Certain Exclusions.</b> The Company shall have no liability for DAMAGES 
              RELATING TO TELECOMMUNICATIONS FAILURES, THE INTERNET, 
              ELECTRONIC COMMUNICATIONS, VIRUSES, SPYWARE AND/OR 
              HARDWARE. Further, it is hereby clarified that the Company shall have no 
              liability in connection with: (a) use of the System and/or the Site not in full 
              compliance with the Agreement; (b) any modification of the System and/or the 
              Site not by the Company; and/or (c) incorporation, attachment and/or conjunction 
              of the System and/or the Site with any other software, hardware, device, site, 
              equipment, application and/or other item.</Box>
              <Box>5. <b>No Warranties</b></Box>
              <Box>5.1 <b>No Warranty.</b>  The System and/or the Site (and any portion of any of the 
              foregoing) is provided "AS IS" without any warranties, including, without 
              limitation, any warranty with respect to its performance, quality, accuracy, use, 
              freedom of bugs, errors, interruptions, omissions, harmful components and/or 
              viruses, immunity from damages, security from unauthorized access, fitness for a 
              particular purpose, merchantability, the title and/or intellectual property therein 
              and/or non-infringement.</Box>
              <Box>5.2 <b>Law Compliance.</b> The Company DISCLAIMS ANY REPRESENTATIONS
              AND/OR WARRANTIES THAT ACCESS TO AND/OR USE OF THE 
              SYSTEM AND/OR THE SITE WILL SATISFY OR ENSURE COMPLIANCE 
              WITH ANY APPLICABLE LAW and/or any third party terms, conditions, rules, 
              guidelines etc. THE Client IS SOLELY RESPONSIBLE FOR ENSURING 
              THAT ITS USE OF THE SYSTEM AND/OR THE SITE AND/OR ANY DATA 
              GENERATED THROUGH ANY OF THE FOREGOING IS IN ACCORDANCE
              WITH APPLICABLE LAW.</Box>
              <Box>5.3 <b>Certain Warranties.</b> The Company MAKES NO WARRANTIES OR 
              REPRESENTATIONS OF ANY KIND, EXPRESS, STATUTORY AND/OR 
              IMPLIED, AS TO: (i)  THE OPERATION, FUNCTIONALITY OR 
              AVAILABILITY OF TELECOMMUNICATION SERVICES AND/OR 
              ACCESS TO THE SYSTEM AT ANY PARTICULAR TIME OR FROM ANY 
              PARTICULAR LOCATION; (ii) ANY LOSS, DESTRUCTION, DAMAGE, 
              CORRUPTION, ATTACK, VIRUSES, INTERFERENCE, HACKING OR 
              OTHER SECURITY INTRUSION OF THE TELECOMMUNICATION 
              SERVICES; AND/OR (iii) ANY DISCLOSURE OF INFORMATION TO 
              THIRD PARTIES, DELETION, MISDELIVERY AND/OR FAILURE TO 
              TRANSMIT ANY DATA, COMMUNICATIONS OR PERSONALIZATION 
              SETTINGS IN CONNECTION WITH the Client's USE OF THE SYSTEM.</Box>
              <Box>5.4  <b>Data Accuracy.</b> The Company provides no warranty, and shall have no 
              liability whatsoever, in connection with the data generated through the Systems 
              and the Site, its completeness and/or accuracy.</Box>
              <Box>6. <b>Indemnification</b></Box>
              <Box>6.1  <b>Indemnification by Company.</b> Subject to the terms and conditions of the 
              Agreement, the Company shall indemnify Client from and against damages to 
              Client resulting from infringement of third party intellectual property rights by the
              System (excluding, for the avoidance of doubt, any open source software, 
              shareware and/or the like therein), which damages are awarded against the Client 
              by a court of competent jurisdiction in a final and not appealable adjudication 
              and/or a definitive written settlement agreement, provided that (i) the Client 
              immediately notifies the Company of any demand and/or claim in connection 
              with such damages; (ii) the Company may assume the defense of and settlement 
              negotiations regarding any demand and/or claim as aforesaid; (iii) the Client shall 
              fully cooperate with the Company, as requested by the Company, in connection 
              with any such claim and/or demand; and (iv) the damages are not resulting from 
              use in breach of any provision of the Agreement, use by the Client of a version of 
              the System and/or the Site that is not the last one made commercially available by
              the Company and/or the use of the System and/or the Site by the Client after it 
              becomes aware of the applicable claim and/or demand. The indemnification 
              pursuant to this Section 6.1 shall be the Client's sole remedy in connection with 
              any violation, misappropriation and/or infringement of any third party rights.</Box>
              <Box>6.2  <b>Indemnification by Client.</b> The Client shall defend, indemnify and hold 
              harmless the Company from any and all damages, losses, liabilities and expenses, 
              including, without limitation, attorneys' fees, arising out of and/or in connection 
              with the Client Content, the Client's access and/or use of the System and/or the 
              Site and/or any breach of the Agreement.</Box>
              <Box>6.3 The client assumes full legal responsibility for his actions and interactions 
              with the subjects of information obtained through the Company's services, and 
              that the Company shall not be liable for any illegal or unauthorized conduct of the
              client in relation to such information. The client shall also acknowledge that it is 
              solely his responsibility to comply with all applicable laws and regulations in the 
              framework of his activity or engagement with the subjects of information, and 
              that the Company shall not be responsible for any legal consequences arising from
              the client's failure to do so.</Box>
              <Box>7. <b>Fees</b></Box>
              <Box>7.1 <b>Fees.</b> The fees for the System access and use and their payment terms shall be
              as set forth in the Subscription Form. The prices in EURO, the Company shall be 
              entitled to changes the prices, at any time, from time to time and its sole 
              discretion, without any notice to anyone. The Client shall not deduce, withhold, 
              set-off and/or the like anything from any payment and/or entitlement of the 
              Company. The Client shall have no lien and/or the like rights in connection with 
              the Agreement.</Box>
              <Box>7.2 <b>VAT.</b> Any payment by Client to the Company shall be supplemented by 
              VAT and/or sales tax pursuant to applicable law, to be paid by Client to the 
              Company with such payment. All fees are exclusive of, and the Client shall bear, 
              any and all taxes, customs, duties and other compulsory payments.</Box>
              <Box>7.3  Payment shall be done by Client through outer payment systems; the 
              Company does not collect or store any data on the Client's payment means, rather 
              such data is processed by the payment system (currently - 
              https://payproglobal.com;) all except as otherwise determined from time to time 
              by the Company, at the Company's sole discretion.</Box>
              <Box>8. <b>Confidentiality</b></Box>
              <Box>8.1 <b>Non-Disclosure.</b>  The Client shall keep strictly confidential any and all 
              information and/or items (whether oral, written, computer based and/or in any 
              other form) thereto disclosed, directly or indirectly, by the Company (the 
              <b>"Confidential Information"</b>). The Client will not, without prior written consent 
              from the Disclosing Party, use (except that the Client may, subject to the terms 
              and condition of the Agreement, use the data generated through its use of the 
              Systems solely as explicitly permitted thereto in the Agreement) or disclose such 
              information. Upon expiration or termination of the Agreement, the Client shall 
              return to the Company all tangible Confidential Information.</Box>
              <Box>8.2  <b>Exclusions.</b> The Client shall have no obligations as set forth in this Section 8 
              with respect to information, which (i) is or become public domain through no 
              breach of this Section 8; (ii) is in possession of the Receiving Party prior to 
              receipt from Disclosing Party; or (iii) is received by the Client from a third party 
              without restrictions.</Box>

              <Box>9. <b>Term</b></Box>
              <Box>9.1  <b>Term.</b> The term of the Agreement is the Term.</Box>
              <Box>9.2  <b>Termination.</b> The Client may, by written notice to the Company, terminate 
              the Agreement after the expiration of forty five (45) days from provision by the 
              Client to the Company with written notice of such breach, during which the 
              Company does cure the breach. The Company, at its sole discretion, shall be 
              entitled to freely terminate the Agreement, at any time and from time to time, 
              whether in whole and/or in part.</Box>
              <Box>9.3  <b>Expiration Affects.</b> Upon expiration or termination of the Agreement, the 
              Client shall cease any access and use of the System and the Site.</Box>
              <Box>9.4  <b>Surviving Provisions.</b> Sections 2.3- 2.7, 2.9, 2.11, 3 and 10, as well as all of 
              the Client's obligations and liabilities in connection with the Agreement, shall 
              survive the expiration or termination of this Agreement for any reason.</Box>
              <Box>9.5  The Company intends, but shall not be obligated in any manner, to grant the 
              Client with access (with limitations and/or restrictions to be determined by the 
              Company, at its sole discretion, at any time and from time to time) to the System 
              for an additional two months following the expiration of the Term; the Client 
              shall have no claims and/or demands in connection with such period and/or the 
              disabling of access as aforesaid and/or earlier and/or later.</Box>
              <Box>9.6 In the event a customer requests the deletion of their account from the 
              Company's system, the User ID and User Client associated with the account may 
              still remain in the system for a specified retention period - two years in order to 
              maintain accurate records of customer activity and to improve the user experience
              by facilitating access to previously used services or products. Additionally, 
              retention of this information may be necessary for the Company to comply with 
              legal or regulatory requirements. The customer made aware of this retention 
              period and the reasons for it in order to ensure transparency and clarity regarding 
              their personal data.</Box>
              <Box>10. <b>General</b></Box>
              <Box>10.1  <b>Assignment.</b> The Client shall not assign, transfer, pledge, charge, encumber,
              delegate or otherwise dispose of its rights and/or obligations under the 
              Agreement, in whole or in part, without the prior written consent of the Company.
              The Company shall be entitled to assign or transfer any and/or all of its rights 
              and/or obligations under the Agreement to any successor thereof, including, 
              without limitation, any acquirer of all or substantially all of the Company's assets 
              and/or any surviving entity under a merger in which the Company participates.</Box>
              <Box>10.2  <b>Notices.</b> All notices under the Agreement shall be in writing. A notice shall 
              be deemed delivered on the first business day after being sent (with electronic 
              sending approval) by the notifying Party to the other Party via facsimile or email, 
              and within ten (10) business days from being deposited in the mail, postage 
              prepaid and addressed to the other Party, at the respective address/number of the 
              notified Party set forth in the Subscription Form (in case of the Client) and the 
              Site (in the case of the Company). Notice provided by the Company in and/or 
              through the System and/or the Website shall be deemed delivered to the Client 
              upon posting thereof.</Box>
              <Box>10.3  <b>Entire Agreement.</b> The Agreement is the complete agreement between the 
              Parties with respect to the subject matters of the Agreement and supersedes all 
              prior agreements between the Parties relating to such subjects. The Company only
              shall be entitled to update, amend and/or change the Agreement, at its sole 
              discretion. The Client shall periodically review the Agreement.</Box>
              <Box>10.4  <b>Law & Venue.</b> The Agreement, including, but not limited to, the validity 
              and construction thereof, shall be governed by the laws of the Singapore (without 
              its conflicts of laws rules). The courts of Singapore shall have sole and exclusive 
              jurisdiction over any and all disputes which shall arise under and/or in connection 
              with the Agreement.</Box>
              <Box>10.5  <b>Severability.</b> To the extent any authorized court rules that any provision of 
              the Agreement is invalid, such provision will be deemed removed from the 
              Agreement without affecting the remainder of the Agreement, provided that the 
              Agreement shall be interpreted, to the maximum extent permitted under 
              applicable law, to give effect to purposes and intentions of the removed provision</Box>
              <Box>10.6  <b>Agreement Confidently.</b> The Client shall not publish and/or disclose the 
              Agreement and/or any of its contents. The Company may use the Client's name 
              and identification as a client of the System and/or the Site in any manner for 
              promotional purposes.</Box>
              <Box>10.7  <b>Waiver.</b>  The waiver of a breach of the Agreement shall not constitute a 
              waiver of the breach of any other provision or of any subsequent breach.</Box>
              <Box>10.8  <b>Third Parties.</b> No third party shall have any rights under the Agreement. If 
              THIRD PARTY request information about the subject of the information 
              provided by the customer. In such cases, the Company will request information 
              about the third party from the customer. It is the customer's responsibility to 
              respond to such requests and to take appropriate actions with respect to the third 
              party.</Box>
              <Box>10.9  <b>Equitable Relief.</b>  The Client acknowledges that any breach of the 
              Agreement thereby will cause material damages to the Company and that in the 
              event of any breach or predicted breach as aforesaid, the Company shall be 
              entitled to equitable relief, such as an injunction, in addition to any other remedies
              the Company may be entitled to under the Agreement and/or applicable law.</Box>
              <Box>10.10 <b>The loss of information.</b> While the Company maintains daily backups of 
              the data, any loss of information that occurs within the daily backup range (24 
              hours) is the responsibility of the customer. The Company shall not be liable for 
              any loss of information that falls within this range.</Box>
              <Box>10.11 <b>Retention period.</b> In accordance with our Terms of Use, we define 
              retention periods for different types of information. Financial-related information 
              will be retained for a period of 3 years for tax purposes, and 7 years for regulatory
              compliance. Other types of information may have different retention periods as 
              determined by applicable laws and regulations.</Box>
              <Box>11. <b>System guidelines</b></Box>
              <Box>11.1 Familiarize yourself with the IRBIS API documentation: The API 
              documentation provides information about the various functions available 
              in the system, including their parameters and expected results.</Box>
              <Box>11.2 Choose the appropriate package: IRBIS offers three standard packages for 
              consumers: Economy, Regular, and Combo. Each package comes with a 
              specific number of search queries per month, so choose the package that 
              fits your needs.</Box>
              <Box>11.3 Use valid inputs: When making a search query, make sure to enter valid 
              inputs to get accurate results. For example, if searching for a phone 
              number, enter the correct country code, area code, and phone number 
              format.</Box>
              <Box>11.4 Monitor your usage: Keep track of the number of search queries you have 
              used in a given month to avoid going over your monthly limit.</Box>
              <Box>11.5 Ensure data privacy: Only use the system for legal purposes and ensure 
              that any personal data collected is handled in accordance with applicable 
              laws and regulations.</Box>
              <Box>11.6 Contact support for assistance: If you encounter any issues or have 
              questions about how to use the system, contact IRBIS support for 
              assistance.</Box>
              <Box>12. <b>Prohibitions against misuse or unauthorized access</b></Box>
              <Box>12.1 Users must not attempt to gain unauthorized access to the system or 
              interfere with its normal functioning.</Box>
              <Box>12.2 Users must not use the system for any illegal or unauthorized purpose.</Box>
              <Box>12.3 Users must not share their login credentials or allow others to use their 
              account.</Box>
              <Box>12.4 Users must not attempt to bypass any security measures or exploit any 
              vulnerabilities in the system.</Box>
              <Box>12.5 Users must not engage in any activity that could damage or disrupt the 
              system or its infrastructure.</Box>
              <Box>12.6 Users must not use the system to harass, threaten, or harm others.</Box>
              <Box>12.7 Users must comply with all applicable laws, regulations, and policies 
              governing their use of the system.</Box>
              <Box>12.8 Users must report any suspected security incidents or breaches to the 
              system administrators immediately.</Box>
              <Box><b>13. Expectations for professional and ethical behavior</b></Box>
              <Box>13.1  The organization expects all users, including customers and their 
              representatives, to conduct themselves in a professional and ethical manner 
              when using the system. This includes but is not limited to refraining from 
              engaging in any activity that could result in the unauthorized access or misuse
              of information.</Box>
              <Box>13.2  The organization has a responsibility to maintain the security and privacy of 
              customer information and will take all necessary measures to ensure the 
              protection of such information. Any breaches of security or misuse of information
              will not be tolerated and may result in legal action.</Box>
              <Box>13.3  Customers are expected to use the system in accordance with the guidelines 
              provided and to refrain from engaging in any prohibited actions. Customers are 
              also responsible for maintaining the security and privacy of their own information
              and must take all necessary measures to ensure its protection.</Box>
              <Box>13.4 Breaches of security or misuse of information by customers may result in 
              suspension or termination of their access to the system, and the organization 
              reserves the right to take legal action as deemed necessary.</Box>
              </List>
          </Typography>
          
          <Typography style={{textDecoration: 'underline', fontWeight: 'bold', display: 'inline-block'}}> PRIVACY POLICY </Typography>
          <Typography style={{display: 'inline-block'}}> 
          <List>
              <Box>
              CLOUDCODE. (<b>"We"</b> or <b>"Us"</b> or <b>“Company”</b>) provides a limited right to access and use 
              the Our SaaS system named "IRBIS" (the <b>"System"</b>), at the Our website 
              https://irbis.espysys.com (the <b>"Site"</b>) and shall be happy to such right to you (<b>"You"</b>). 
              <b>Your privacy is important to Us and We truly respect it. We are committed to 
              exercising efforts to protect Your privacy and recognize Your need for appropriate 
              protection and management of privacy, as it relates to Your use of the Systems 
              and/or the Site. We intend to process Personal Information in a legal, fair and 
              transparent manner, using legal, organizational and technical measures to so do.
              To better protect your privacy, we provide this Privacy Policy (the “Policy”), 
              explaining Our practices with respect to Your private information that You submit 
              to Us.  
              Should You have any questions regarding this Policy, please contact Us at</b>
              info@cloudcode.pro
              In connection with Our Services, you may submit, upload and/or communicate, and/or 
              We may collect and/or store, your information identifying You personally, including, 
              without limitation, as set forth in the registration forms (the <b>"Personal Information"</b>).
              The Company acknowledges that the customer is the controller of any personal data 
              entered into or collected as part of the searches requested by the customer. The Company 
              acts only as a processor of such personal data and shall process it only on the instructions 
              of the customer, unless required to do otherwise by law.
              The Company shall not be responsible for the accuracy or legality of any personal data 
              entered or collected as part of the searches requested by the customer. The customer shall
              be solely responsible for ensuring that such personal data is accurate, complete, and 
              lawful, and that the customer has obtained all necessary consents and authorizations for 
              the processing of such personal data by the Company.
              Customer acknowledges and agrees that the Company is acting only as a processor of 
              personal data and is not responsible for the accuracy or legality of such personal data.
              In accordance with applicable regulations, any handling of third-party information shall 
              be carried out in a similar manner to the handling of personal data. This requirement 
              applies to all activities not conducted within the <b>System</b>, such as locating or using 
              information outside of the system, as well as instances where Company uses information 
              collected for multiple customers or transfers such information to external parties 
              (including interfaces created by the customer). This also includes the use of information 
              about users of the system.
              The Personal Information may include Your contact details, Your account data, 
              information regarding use of the System, the Site and/or the internet, Your reviews, 
              opinions, preferences, interests, requests, complaints etc., and/or Your location.
              Those may be collected, inter alia, through You filling in Site and/or System forms, other 
              channels related to the Site and/or System and/or automatically in the process of Your use
              of the Systems and/or the Site. We attempt to store Personal Information on reasonably 
              secure media, and it’s processed both automatically and in other manners.
              By accessing and/or using the Site and/or the System, you confirm that You, on your only
              free will, provide Your Personal Information to Us, agree to its processing by Us, without
              any restrictions, and that the Personal Information is Yours personally and You confirm 
              that You carefully read this Policy and agree to all terms and conditions of the Privacy 
              Policy.
              We may use the Personal Information (including, without limitation, through Our third-
              party service providers) to provide and/or improve the System and/or the Site, access to 
              and/or use and/or security of any of the foregoing, to provide services, with respect to 
              ordering and/or registration to access the Systems and/or the Site, to contact You, to 
              obtain inputs from You, to provide You with information, email you in connection with 
              Our activity and/or event and/or the Site and/or the System, to identify and authenticate 
              Your access to the System and/or the Site, in connection with your payments to Us and/or
              in connection with any matter related to any of the foregoing. [I specifically agree to this 
              paragraph __]
              Further, We shall have the right to access, read, preserve, disclose and use any 
              information, including, inter alia, the Personal Information, as We reasonably believe is 
              required in order to (a) satisfy any applicable law, legal process, governmental request or 
              the like, (b) enforce Our Terms of Use, including, without limitation, investigation of 
              potential violations thereof, (c) detect, prevent, or otherwise address, fraud, security or 
              technical issues, and/or systems integrity purposes; (d) respond to user support requests, 
              (e) protect the rights, property or safety of Ours, the System, the Site and/or the users of 
              any of the foregoing, and/or (f) maintain appropriate business records. [I specifically 
              agree to this paragraph __]
              Moreover, We may use, copy, modify, develop, disclose, distribute and publish the 
              Personal Information (i) in connection with the System and/or the Site, and/or any 
              improvement of the System and/or the Site, the operation of any of the foregoing, the 
              provision of access to any of the foregoing to You and/or to others and/or any services 
              related to any of the foregoing (ii) in connection with any cooperation between Us and 
              any third party, including, but not limited to, for advertising targeting, as well as in 
              connection with any marketing and/or promotional activities; (iv) Our acquisition (or of 
              any assets from Us) by any third party; and/or (v) any other legitimate commercial 
              purposes of Ours. [I specifically agree to this paragraph __]
              Further, We may also use the Personal Information to send <b>(including, without 
              limitation, by email and/or Message)</b> news re the System and/or the Site, updates, 
              marketing materials, newsletters and/or the like and/or disclose the Personal Information 
              to those of our partners who may have relevant offers and permit them to use it for the 
              purpose of such offers. In the event we provide you with information as aforesaid by 
              email or Message, you will be allowed to remove Yourself from the respective mainly 
              list. [I specifically agree to this paragraph __]
              Additionally, We may transfer Your Personal Information to countries other than the 
              country it was received from. We will attempt to ensure that such other countries protect 
              personal data, however We may transfer Personal Information to countries that do not 
              protect personal data with Your consent, in connection with the performance of the 
              Terms of Use and/or protection of vital interests.
              The Site and/or the System may utilize tracking technologies (such as cookies and the 
              like), for example, to collect and/or record information about Site and/or System activity 
              and/or use and/or browsing activities and/or to recall information previously indicated by 
              a user. We do not guarantee to respond to do-not-trach requests or that You may disable 
              the foregoing. The Site and/or the System may use third parties to present or serve 
              advertisements; such third parties may use technologies to help present advertisements 
              and measure and research the advertisements' effectiveness; such use is subject to such 
              third parties privacy policies and is not covered by this Policy. Our Cookies Policy shall 
              be deemed part of this Policy.
              The System and/or the Site may utilize or reference third party systems, applications 
              and/or sites and may be referenced in third party systems, applications and/or sites; You 
              shall be bound by the privacy policies of those (in addition to this Policy); We shall have 
              no responsibility and/or liability in connection with the privacy practices and/or breaches 
              by such third parties or any other third party.
              We may retain Personal Information for as long as required for the purpose of their 
              collection and use or longer periods, as required for Our legal obligations and/or duties 
              and/or to defend claims.
              In case that your account with Us is inactive for two (2) years, we will promptly delete 
              such account and Your Personal Information in the Systems. Within thirty (30) business 
              days of Your request from Us, We will promptly delete Your Personal Information in the 
              System, to the extent mandatory pursuant to applicable law. Notwithstanding any other 
              provision, we may continue to store all or any of Your Personal Information, as required 
              under applicable law. We will be entitled to delete Your Personal Information in case that
              breach and/or violate in any manner The systems/Site Terms of Use and/or at any other 
              time decided by Us, at Our sole discretion.
              You are entitled to reasonably receive information on processing Your Personal 
              Information. You have the right to access Your Personal Information in the System and 
              are invited to contact Us in such regard at the email address referenced above.
              You shall be sensible and responsible in placing Personal Information in the System, the 
              Site and/or the public domain. You confirm that all information provided by You is 
              accurate, updated and Yours. We do not undertake to check any data and/or information 
              You provide and do not undertake to store any data and/or information whatsoever.
              We cannot guarantee perfection of privacy. Notwithstanding any other provision of this 
              Policy and/or the System/Site Term of Use, we will have no liability in connection with 
              any confidentiality or privacy deficiencies, except in cases of willful misconduct. This 
              Privacy Policy shall not impose on Us any liability, obligation and/or duty more than 
              those imposed on Us in respect of data protection pursuant to applicable law.
              We may, at any time and from time to time, amend and/or change this Policy. You should
              review this regularly review this, Policy.
              [I agree (without limitation of any specific consent provided) ___] 
              </Box>
          </List>
          </Typography>
            </Container> 
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose}>
              OK
            </Button>
          </DialogActions>
        </Dialog>
        </>
      ) : (
        <Grid item mt={3}>
            <Typography variant="semiBold">{t('auth.register.backendfail')}</Typography>
        </Grid>
      )}
    </Page>
  )
}