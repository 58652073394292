import React, { MouseEventHandler, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Divider,
  Grid, InputAdornment,
  Link, OutlinedInput,
  Stack, Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import {
  CompareButton,
  ExportButton,
  ResultCriteria,
  ResultCriteriaTitle,
  ResultName, ResultNameTitle, ResultNotVerified,
  ResultTitle,
  ResultVerified,
} from '../../style/result'
import sprite_ from '../../sass/sprite.module.sass'
import { Masonry } from '@mui/lab'
import { useDispatch, useSelector } from '../../store'
import { getViewResult } from '../../store/searcher/selector'
import { Image, prepareCommonResult, PreparedCommonResult, VerifiedNames, Name } from '../../store/searcher/service'
import { RenderImage } from '../../component/searcher/RenderData'
import { PhoneCommon } from './details/PhoneCommon'
import { NameCommon } from './details/NameCommon'
import { PostCommon } from './details/PostCommon'
import { DeepWebCommon } from './details/DeepWebCommon'
import { getNameVerificationCriteria, getTitle } from '../../function/string'
import { useTranslation } from 'react-i18next'
import { search } from '../../function/data'
import { LinkPreview } from '../../component/searcher/LinkPreview'
import emptyImage from '../../asset/icon/defaultUser.png'
import { FaceSearchIdItem, FindCloneItem, ILookup } from '../../store/searcher/reducer'
import { SentimentAnalysisCommon } from './details/SentimentAnalysisCommon'
import { ImageCommon } from './details/ImageCommon'
import { JsonCommon } from './details/JsonCommon'
import { VerifiedServices } from './details/VerifiedServices'
import { VerifiedServicesInline } from './details/VerifiedServicesInline'
import { KYCCommon } from './details/KYCCommon'
import { IPGeolocationCommon } from './details/IPGeolocationCommon'
import { WebCollectionCommon } from './details/WebCollectionCommon'
import { startVerify } from '../../store/searcher/actionCreator'
import { number } from 'joi'
import { IpPhoneGeolocationCommon } from './details/IpPhoneGeolocationCommon'
import { PsychoProfileCommon } from './details/PsychoProfileCommon'
import CircularProgress from "@mui/material/CircularProgress"
import CircularDeterminate from './result_components/ProgressComponent'
import CollapsibleTable from './details/NameApiCommon'


const searchExcludes = [
  'profile_id',
  'keyphrase',
  'keyword',
  'friendOf',
  'friendName',
  'friendProfileId',
  'friendImageUrl',
  'customrequestid',
  'type',
  'album_name',
  'setting=RABBIT_MQ_STATUS_MONITORING_QUEUE_NAME',
  'actualMainUrl',
  'profileCoverPhotoUrl',
]

type Props = {
  onClose: () => void,
  isMobile?: boolean,
}
const PopupCommon = ({ onClose, isMobile = false }: Props) => {
  //selectors
  const apiData = useSelector(getViewResult)
  const theme = useTheme()
  //state
  const { t } = useTranslation()
  const [activeImage, setActiveImage] = useState<Image>()
  const [viewData, setViewData] = useState<PreparedCommonResult>()
  const [searchValue, setSearchValue] = React.useState('')
  const [compareImage, setCompareImage] = useState<FaceSearchIdItem>()
  const dispatch = useDispatch()

  const user = useSelector((state) => state.settings)
  
  let fakeCheckPrice : any = null
    if (user.package && user.package.lookups) {
    user.package.lookups.map( (el : ILookup) => {
      if ( el.fakeCheckPrice !== null) {
        fakeCheckPrice = el.fakeCheckPrice
      }
    })
  }

  let fakeCheckCredits : any = 0
  
  let score = 0
  if (viewData?.verifiedScore) {
  score = viewData?.verifiedScore
  }
  
  if (fakeCheckPrice && user.package?.creditsPerUnit && user.package?.creditsPerUnit > 0) {
    fakeCheckCredits = (fakeCheckPrice && user.package ) ? fakeCheckPrice * user.package?.creditsPerUnit : 1
  } else {
    fakeCheckCredits = (fakeCheckPrice && user.package ) ? fakeCheckPrice : 1 
  }
  

  // const filteredData: any = React.useMemo(() => {
  //   let filtered
  //   return filtered
  // }, [searchValue])

  //effects
  useEffect(() => {
    if (apiData) {
      const vd = prepareCommonResult(apiData)
      setViewData(vd)
      if (vd?.images?.length) {
        setActiveImage(vd.images[0])
      }
    }
  }, [apiData])

  //handlers
  const handleImageClick = (el: any, image: Image) => {
    el.preventDefault()
    setActiveImage(image)
  }

  const handleCompareImageClick = (cImage: any) => {
    setCompareImage(cImage)
  }
  
  const tableLimit = 6

  const validatePhone = () => {
    if (apiData?.requestId && viewData?.names && fakeCheckPrice !== null) {
    const data : VerifiedNames = {
      source: [],
      name: [],
      checkPrice: 0,
      requestId: '',
    }
    data.checkPrice = fakeCheckPrice
    data.requestId = apiData?.requestId
    viewData?.names.forEach( (el : Name) => {
      data.name.push(el.name)
      data.source.push( el.app ? el.app : 'fb' )
    } )
    dispatch(startVerify(data, tableLimit))
    onClose()
    }
  }

  function getResultTopIcon(){
    // return apiData?.type !== 'deepweb' ? apiData?.type : viewData?.deepwebType
    let iconType
    if (apiData && apiData.type){
      if (apiData.type === 'deepweb' && viewData && viewData.deepwebType){
        iconType = viewData.deepwebType
      }
      else if (apiData.type === 'combined_id' && apiData.sources.find((src) => src.name = 'instanavigation_person')) {
        iconType = 'instanavigation_person'
      }
      else if (apiData.type === 'psycho_profile' && apiData.sources.find((src) => ['PSYCH', 'SUMMARY', 'psych', 'summary'].includes(src.name))){
        iconType = 'fb'
      }
    }
    return iconType ? sprite_[`social__small_${iconType}`] : undefined
  }
  
  
  return (
    <Dialog
      open={true}
      fullWidth
      onClose={onClose}
      maxWidth="md"
      sx={{
        '.MuiPaper-root': {
          padding: 0,
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2, backgroundColor: '#F0F2FA'}}>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} flexWrap={'wrap'}>
          <Typography fontSize={16} color={'#747474'} sx={{}}>
            {apiData?.type && ['phone', 'combined_phone'].includes(apiData.type) && 'Phone lookup results'}
            {apiData?.type && ['name', 'combined_name', 'combined_id', 'name_api'].includes(apiData.type) && 'Social networks profiles'}
            {apiData?.type && ['combined_email'].includes(apiData.type) && 'Search by email'}
            {apiData?.type === 'post' && 'Posts observer results'}
            {apiData?.type === 'deepweb' && 'Pluto lookup results'}
            {apiData?.type === 'face_search' && 'Face seach results'}
            {apiData?.type === 'sentiment_analysys' && 'Sentiment Analysys'}
            {apiData?.type === 'irbis_eye' && 'Irbis eye results'}
            {apiData?.type === 'web_data_search' && 'Facebook API'}
            {apiData?.type === 'fb_person_wall_keyword' && 'Facebook API Keywords'}
            {apiData?.type === 'kyc' && 'KYC'}
            {apiData?.type === 'ip_geo' && 'IP Geolocation'}
            {apiData?.type === 'ipgeo_number' && 'Irbis Validation'}
            {apiData?.type === 'web_collection' && 'WEB Collection'}
            {apiData?.type === 'name_by_country' && 'Name by Country'}
            {apiData?.type === 'photo_searcher' && 'Sniper API results'}
            {apiData?.type === 'psycho_profile' && 'Psychological Profile Analysis'}
            {apiData?.type === 'lookup_phone_name_verification' && 'Lookup Name Verification'}
            {apiData?.type === 'real_phone' && 'Real Person Or Not'}
            
          </Typography>
          {
            apiData?.type !== 'sentiment_analysys' && <ResultCriteria>
              <Box
                className={getResultTopIcon()}
                sx={{marginRight: '10px'}}
              />
              {
                ['face_search', 'irbis_eye', 'photo_searcher'].includes(apiData?.type + '')
                  ? <img
                    src={apiData?.criteria}
                    alt=''
                    width='40'
                    height='50'
                    style={{ objectFit: 'cover' }}
                  />
                  : <Tooltip title={apiData?.criteria || ''} placement="bottom">
                    <ResultCriteriaTitle>{ apiData?.type === 'lookup_phone_name_verification' ? 
                      getNameVerificationCriteria(apiData.criteria, 25) : apiData?.criteria}</ResultCriteriaTitle>
                  </Tooltip>
              }
            </ResultCriteria>
          }
          {
            (apiData?.type === 'combined_phone' && fakeCheckPrice && apiData.status === 'finished' && viewData?.isVerifiedName === false) &&
            <Box sx={{flexShrink: 0, m: 0, p: 2 } }>
              <Button type="submit" variant="contained"
              onClick={validatePhone}
              fullWidth sx={{
                [theme.breakpoints.only("xs")]: {
                  //width: '60%',
                },
              }}>
                { user.package?.creditsPerUnit ? `Validate for ${fakeCheckCredits} Cred's` : `Validate for €${fakeCheckCredits}`}
              </Button>
            </Box>
          }
          {
            (apiData?.type === 'combined_phone' && fakeCheckPrice && apiData.status === 'finished' && viewData?.isVerifiedName === true) &&
            <Box sx={{flexShrink: 0, m: 0, p: 2 } }>
            {
            (score <= 0.2 ) &&
            <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} color="red">
              {CircularDeterminate({ progress: Math.round( (score as number) * 100), color: "#FF0000" })}
            </Stack>
            }
            {
            (score > 0.2 && score <= 0.4 ) &&
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', flexWrap: 'wrap' }}>
            {/* {`${viewData?.finalClassification} (${ Math.round( (viewData?.verifiedScore as number) * 100) }%)`} */}
            {CircularDeterminate({ progress: Math.round( (score as number) * 100), color: "#FFD800" })}
            </Box>
            }
            {
              (score > 0.4 && score <= 1 ) &&
              <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} color="green">
              {CircularDeterminate({ progress: Math.round( (score as number) * 100) , color: "#03C04A" })}

              </Stack>
            }
            </Box>
          }
        </Stack>
      </DialogTitle>
      {
        viewData && <DialogContent sx={{padding: '30px', marginTop: '30px'}} className={'custom-scroll'}>
          <Stack
            direction={'row'}
            alignItems={viewData?.useTaro ? 'flex-start' : 'center'}
            justifyContent={'center'}
            sx={{
              [theme.breakpoints.only("xs")]: {
                flexDirection: 'column',
              },
            }}
          >
            {
              !['combined_phone','real_phone','phone','verify_name','phone_list'].includes(apiData?.type || '') &&
              <Stack direction={'column'} alignItems={'center'} justifyContent={'center'}>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
                  {
                    viewData.foundSources.map((app, idx) =>
                      <Tooltip key={idx} title={getTitle(app) || ''} placement="top">
                        <Box key={idx} className={sprite_[`social__large_${app}`]} sx={{marginRight: '8px'}} />
                      </Tooltip>,
                    )
                  }
                </Stack>
                {
                  viewData?.useTaro && <VerifiedServicesInline found={true} socialNetworks={viewData.verifiedServices} />
                }
              </Stack>
            }
            {
              !['combined_phone','real_phone','phone','verify_name','phone_list'].includes(apiData?.type || '') && viewData.foundSources?.length > 0 && viewData.notFoundSources?.length > 0 && <Divider
                sx={{
                  height: '50px',
                  borderColor: 'rgba(46, 151, 213, 0.5)',
                  margin: '0 10px',
                  [theme.breakpoints.only("xs")]: {
                    height: '20px',
                    margin: '10px 0',
                  },
                }}
                orientation={'vertical'}
              />
            }
            {
              !['combined_phone','real_phone','phone','verify_name','phone_list'].includes(apiData?.type || '') &&
              <Stack direction={'column'} alignItems={'center'} justifyContent={'center'}>
                <Stack direction={'row'} alignItems={'center'} justifyContent={'center'} sx={{marginLeft: '20px', flexWrap: 'wrap'}}>
                  <Typography sx={{marginRight: '5px'}} fontSize={14} color={'#747474'}>Not found:</Typography>
                  {
                    viewData.notFoundSources.map((app, idx) =>
                      <Tooltip key={idx} title={getTitle(app) || ''} placement="top">
                        <Box key={idx} className={sprite_[`social__small_${app}`]} sx={{marginRight: '8px', opacity: '0.6'}} />
                      </Tooltip>,
                    )
                  }
                </Stack>
                {
                  viewData?.useTaro && <VerifiedServicesInline found={false} socialNetworks={viewData.verifiedServices} />
                }
              </Stack>
            }
          </Stack>

          {
            ['face_search', 'irbis_eye', 'photo_searcher'].includes(apiData?.type || '') && <ImageCommon
              viewData={viewData}
              apiData={apiData}
              compareImage={compareImage}
              handleCompareImage={handleCompareImageClick}
            />
          }
          {
            !['face_search', 'irbis_eye', 'photo_searcher', 'sentiment_analysys', 'ip_geo', 'ipgeo_number', 'psycho_profile', 'kyc', 'web_collection', 'name_api'].includes(apiData?.type || '') && <Stack sx={{padding: '20px'}}>
              <Grid container spacing={2}>
                {
                  activeImage && <Grid item xs={'auto'}>
                    <Box sx={{width: '220px', height: '250px'}}>
                      <RenderImage
                        _key={'activeImage'}
                        val={activeImage.image}
                        imgHeight={'250px'}
                      />
                    </Box>
                  </Grid>
                }
                <Grid item xs>
                  <Stack direction={'column'} alignItems={'space-between'} justifyContent={'space-between'} sx={{height: '100%', width: '100%'}}>
                    {
                      viewData.images.length > 0 && <Stack direction={'row'} flexWrap={'wrap'} rowGap={1}>
                        {
                          viewData.images.map((image, idx) => <Box key={idx} sx={{width: '67px', height: '75px', marginRight: '10px', position: 'relative'}}>
                            <RenderImage
                              _key={idx + ''}
                              val={image.image}
                              imgHeight={'75px'}
                              onClick={(e) => handleImageClick(e, image)}
                            />
                            <Box className={sprite_[`social__small_${image.app}`]} sx={{marginRight: '8px', width: '20px', position: 'absolute', top: '2px', left: '2px'}} />
                          </Box>)
                        }
                      </Stack>
                    }
                    <Stack direction={'column'} sx={{margin: '20px 0'}}>
                      <Stack
                        direction={'row'}
                        sx={{
                          [theme.breakpoints.only("xs")]: {
                            flexDirection: 'column',
                          },
                        }}
                      >
                        <ResultName>
                          <Tooltip title={viewData.name || ''} placement="bottom">
                            <ResultNameTitle>
                              { (apiData?.type === 'lookup_phone_name_verification') ? getNameVerificationCriteria(viewData.name, 15) : viewData.name}
                              {
                                viewData?.criteriaCountry && <img
                                  loading='lazy'
                                  width='20'
                                  height='15'
                                  src={`https://flagcdn.com/w20/${viewData.criteriaCountry.toLowerCase()}.png`}
                                  srcSet={`https://flagcdn.com/w40/${viewData.criteriaCountry.toLowerCase()}.png 2x`}
                                  alt=''
                                />
                              }
                            </ResultNameTitle>
                          </Tooltip>
                        </ResultName>
                        {
                          viewData.foundSources.length > 0
                            ? console.log('Verified 100%')//<ResultVerified>Verified 100%</ResultVerified>
                            : console.log('Results not found')//<ResultNotVerified>Results not found</ResultNotVerified>
                        }
                      </Stack>
                      {
                        viewData.createdDate && <Stack direction={'row'} sx={{marginTop: '10px'}}>
                          <Typography fontSize={16} color={'#214A98'} fontWeight={700} sx={{marginRight: '10px'}}>Retrieved:</Typography>
                          <Typography fontSize={16} color={'#747474'} fontWeight={400}>{viewData.createdDate}</Typography>
                        </Stack>
                      }
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          }

          {
            !['sentiment_analysys'].includes(apiData?.type || '') && <Box sx={{ width: '100%', marginBottom: '30px' }}>
              <Masonry columns={isMobile ? 1 : 2} spacing={1}>
                {
                  viewData.names.length > 0 && <Box sx={{ height: 'auto', width: '100%' }}>
                    <Stack direction={'column'} sx={{margin: '10px 0'}}>
                      <ResultTitle>Names:</ResultTitle>
                      {
                        viewData.names.map((name, idx) => <Stack key={idx} direction={'row'} sx={{}} alignItems={'center'}>
                          {
                            name?.app && <Tooltip title={ getTitle(name?.app) } placement="top">
                              <Box className={sprite_[`social__small_${name.app}`]} sx={{marginRight: '8px'}} />
                            </Tooltip>
                          }
                          <Typography fontSize={18} fontWeight={400} color={'#747474'} sx={{wordBreak: 'break-all'}}>{name.name}</Typography>
                        </Stack>)
                      }
                    </Stack>
                  </Box>
                }
                {
                  viewData.phones.length > 0 && <Box sx={{ height: 'auto', width: '100%' }}>
                    <Stack direction={'column'} sx={{margin: '10px 0'}}>
                      <ResultTitle>Phones:</ResultTitle>
                      {
                        viewData.phones.map((name, idx) => <Stack key={idx} direction={'row'} sx={{}} alignItems={'center'}>
                          {
                            name?.app && <Tooltip title={name?.app} placement="top">
                              <Box className={sprite_[`social__small_${name.app}`]} sx={{marginRight: '8px'}} />
                            </Tooltip>
                          }
                          <Typography fontSize={18} fontWeight={400} color={'#747474'} sx={{wordBreak: 'break-all'}}>{name.name}</Typography>
                        </Stack>)
                      }
                    </Stack>
                  </Box>
                }
                {
                  viewData.socialNetworks.length > 0 && <Box sx={{ height: 'auto', width: '100%' }}>
                    <Stack direction={'column'} sx={{margin: '10px 0', width: '100%'}}>
                      <ResultTitle>Social Networks:</ResultTitle>
                      {
                        viewData.socialNetworks.map((name, idx) => <Stack key={idx} direction={'row'} sx={{height: '83px', width: '100%'}} alignItems={'center'}>
                          <Box className={sprite_[`social__small_${name?.app}`]} sx={{marginRight: '8px', width: '20px'}} />
                          {
                            name.url
                              ? <LinkPreview title={name.socialNetwork} link={name.url} style={{height: 'fit-content', width: 'calc(100% - 28px)'}} defaultIcon={name?.app} />
                              : <Typography fontSize={18} fontWeight={400} color={'#747474'} sx={{wordBreak: 'break-all'}}>{name.socialNetwork}</Typography>
                          }
                        </Stack>)
                      }
                    </Stack>
                  </Box>
                }
                {
                  viewData.birthday.length > 0 && <Box sx={{ height: 'auto', width: '100%' }}>
                    <Stack direction={'column'} sx={{margin: '10px 0'}}>
                      <ResultTitle>Birthday:</ResultTitle>
                      {
                        viewData.birthday.map((name, idx) => <Stack key={idx} direction={'row'} sx={{}} alignItems={'center'}>
                          <Box className={sprite_[`social__small_${name.app}`]} sx={{marginRight: '8px'}} />
                          <Typography fontSize={18} fontWeight={400} color={'#747474'} sx={{wordBreak: 'break-all'}}>{name.birthday}</Typography>
                        </Stack>)
                      }
                    </Stack>
                  </Box>
                }
                {
                  viewData.emails.length > 0 && <Box sx={{ height: 'auto', width: '100%' }}>
                    <Stack direction={'column'} sx={{margin: '10px 0'}}>
                      <ResultTitle>Emails:</ResultTitle>
                      {
                        viewData.emails.map((name, idx) => <Stack key={idx} direction={'row'} sx={{}} alignItems={'center'}>
                          <Box className={sprite_[`social__small_${name.app}`]} sx={{marginRight: '8px'}} />
                          <Typography fontSize={18} fontWeight={400} color={'#747474'} sx={{wordBreak: 'break-all'}}>{name.email}</Typography>
                        </Stack>)
                      }
                    </Stack>
                  </Box>
                }
                {
                  viewData.urls.length > 0 && <Box sx={{ height: 'auto', width: '100%' }}>
                    <Stack direction={'column'} sx={{margin: '10px 0'}}>
                      <ResultTitle>Web Sites:</ResultTitle>
                      {
                        viewData.urls.map((url, idx) => <Stack key={idx} direction={'row'} sx={{}} alignItems={'center'}>
                          <a href={url.link} target={'_blank'}>
                            <Typography fontSize={18} fontWeight={400} color={'#747474'} sx={{wordBreak: 'break-all'}}>{url.title}</Typography>
                          </a>
                        </Stack>)
                      }
                    </Stack>
                  </Box>
                }
                {
                  viewData.addresses.length > 0 && <Box sx={{ height: 'auto', width: '100%' }}>
                    <Stack direction={'column'} sx={{margin: '10px 0'}}>
                      <ResultTitle>Addresses:</ResultTitle>
                      {
                        viewData.addresses.map((name, idx) => <Stack key={idx} direction={'row'} sx={{}} alignItems={'center'}>
                          <Box className={sprite_[`social__small_${name.app}`]} sx={{marginRight: '8px'}} />
                          <Typography fontSize={18} fontWeight={400} color={'#747474'} sx={{wordBreak: 'break-all'}}>{name.address}</Typography>
                        </Stack>)
                      }
                    </Stack>
                  </Box>
                }
              </Masonry>
            </Box>
          }

          {
            apiData && ['phone', 'combined_phone', 'combined_email'].includes(apiData.type) && <>
              <ResultTitle>Details:</ResultTitle>
              <PhoneCommon apiData={apiData} />
            </>
          }

          {
            apiData
              && ['name', 'combined_name', 'combined_id'].includes(apiData.type)
              && <NameCommon apiData={apiData} />
          }

          {
            apiData && apiData.type === 'post' && <>
              <ResultTitle>Posts:</ResultTitle>
              <PostCommon apiData={apiData} />
            </>
          }

          {
            apiData
              && ['deepweb', 'combined_email', 'combined_name', 'combined_phone', 'combined_id', 'name_by_country'].includes(apiData.type)
              && <DeepWebCommon apiData={apiData} />
          }

          {
            apiData
              && ['sentiment_analysys'].includes(apiData.type)
              && <SentimentAnalysisCommon apiData={apiData} />
          }
          {
            apiData
              && ['kyc'].includes(apiData.type)
              && <KYCCommon key={'kyc'} apiData={apiData} />
          }
          {
            apiData
              && ['ip_geo'].includes(apiData.type)
              && <IPGeolocationCommon apiData={apiData} />
          }
          {
            apiData
              && ['ipgeo_number'].includes(apiData.type)
              && <IpPhoneGeolocationCommon apiData={apiData} />
          }
          {
            apiData
              && ['web_collection'].includes(apiData.type)
              && <WebCollectionCommon apiData={apiData} />
          }
          {
            apiData
              && ['psycho_profile'].includes(apiData.type)
              && <PsychoProfileCommon apiData={apiData} />
          }
          {
            apiData
              && ['name_api'].includes(apiData.type)
              && <CollapsibleTable apiData={apiData}/>
          }
          {
            apiData
            && ['web_data_search', 'fb_person_wall_keyword', 
                  'sn_api_profiler', 'sn_api_group_profiler', 'api_v2_x', 'api_v2_instagram', 'api_v2_linkedin', 'lookup_phone_name_verification', 'real_phone',
                    'sn_api_profiler_fb_friends', 'sn_api_profiler_fb_posts', 'sn_api_profiler_insta_friends', 
                      'sn_api_profiler_insta_posts', 'irbis_facebook', 'irbis_instagram',
                        'crolo_x','crolo_vk','crolo_ok','irbis_tiktok','irbis_criminal', 
                          'ssn_trace', 'nat_crim_check', 'pep'].includes(apiData.type)
            && <JsonCommon apiData={apiData} />
          }
        </DialogContent>
      }
      <DialogActions sx={{padding: '30px'}}>
        <Button
          size="large"
          color="primary"
          sx={{
            marginLeft: 2,
            textTransform: 'uppercase',
          }}
          onClick={onClose}
        >
          <Typography
            color="primary"
            sx={{
              fontWeight: 600,
              fontSize: '16px',
              lineHeight: '24px',
              color: '#40A0D9',
            }}
          >
            Close
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PopupCommon