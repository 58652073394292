import { SearcherAction } from './actionType'

export type Type = 'phone' | 'name' | 'name_api' | 'post' | 'deepweb' 
| 'webint' | 'combined_phone' | 'combined_email' | 'combined_name' | 'combined_id' 
|  'face_search' | 'web_data_search' | 'fb_person_wall_keyword' | 'sentiment_analysys' 
| 'irbis_eye' | 'demo' | 'kyc' | 'web_collection' | 'ip_geo' | 'ipgeo_number'
| 'name_by_country' | 'verify_name' | 'photo_searcher' | 'phone_list'
| 'psycho_profile' | 'psycho_profile_summary' | 'api_v2_x' | 'api_v2_instagram' 
| 'api_v2_linkedin' | 'sn_api_profiler' | 'lookup_phone_verification' | 'lookup_phone_name_verification' | 'real_phone' 
| 'sn_api_profiler_fb_friends' | 'sn_api_profiler_fb_posts' | 'sn_api_profiler_insta_friends' | 'sn_api_profiler_insta_posts'
| 'irbis_facebook' | 'crolo_x' | 'crolo_vk' | 'crolo_ok' | 'irbis_instagram' | 'irbis_tiktok' | 'irbis_criminal'
| 'sn_api_group_profiler' | 'bluesky_v3' |  'ssn_trace' | 'nat_crim_check' | 'pep'

export type Status = 'progress' | 'finished' | 'failed' | 'empty' | number

export type Phone =
  | 'getcontact'
  | 'drupe'
  | 'skype'
  | 'viber'
  | 'telegram'
  | 'whatsapp'
  | 'eyecon'
  | 'tc'
  | 'callapp'
  | 'hiya'
  | 'imo'
  | 'tamtam'
  | 'botim'
  | 'ip'
  | 'luna'
  | 'taro'
  | 'web_collection_phone'
  | 'web_collection_email'
  | 'eyes'
  | 'mail_reveal'
  | 'caller_api'

export type Social = 'fb' | 'instagram' | 'linkedin' | 'twitter' | 'ok' | 'vk'

export type NameApi = 'fb' | 'instagram' | 'linkedin' | 'x'

export type DeepWeb = 'email'
  | 'phone'
  | 'facebookid'
  | 'telegramid'
  | 'password'
  | 'linkedinid'
  | 'vkid'
  | 'fullname'
  | 'twitterid'
  | 'instagramid'
  | 'pluto'
  
export type WebInt = 'quotaprofiler-lookup' | 'quotaprofiler-crawler' | 'quotaprofiler-cases' |'quotaprofiler-periodic' | 'quotaprofiler-monitoring' |
  'quotawebint-lookup' | 'quotawebint-crawler' | 'quotawebint-cases' | 'quotawebint-periodic' | 'quotawebint-monitoring'


export type CombinedPhone = | 'getcontact' | 'drupe' | 'skype' | 'viber' | 'telegram' | 'whatsapp' | 'eyecon' | 'tc' | 'callapp'
  | 'hiya' | 'imo' | 'tamtam' | 'phone' | 'botim' | 'luna' | 'taro' | 'web_collection_phone'

export type CombinedEmail = | 'skype' | 'email' | 'luna' | 'taro' |'web_collection_email' | 'eyes' | 'mail_reveal'

export type CombinedNames = | 'fb' | 'instagram' | 'linkedin' | 'twitter' | 'ok' | 'vk' | 'fullname' | 'webmii' 
| 'fb_api' | 'instagram_api' | 'x_api' | 'vk_api' | 'ok_api' | 'linkedin_api' | 'tiktok_api' | 'blsk_api'

export type CombinedId = | 'fb' | 'instagram' | 'linkedin' | 'twitter' | 'ok' | 'vk' 
                        | 'facebookid' | 'telegramid' | 'linkedinid' | 'vkid' | 'twitterid' | 'instagramid' | 'instanavigation_person'

export type FaceSearch = | 'ok' | 'vk' | 'pluto' | 'tiktok' | 'sniper' | 'sniper_2'

export type SentimentAnalysys = | 'sentiment_analysys'

export type FindClone = | 'irbis_eye'

export type KYC = | 'kyc_iban' | 'kyc_company_enrich' | 'kyc_vat_validate'

export type WEB_Collection = | 'web_collection_screenshot' | 'web_collection_scrape'

export type IPGeo = | 'geolocation'

export type IPGeoNumber = | 'geolocation_number' | 'email_domain_validator'

export type NameByCountry = | 'country_fullname'

export type SniperAPI = | 'sniper_api'

export type PhoneList = | 'getcontact' | 'drupe' | 'skype' | 'viber' | 'telegram' | 'whatsapp' | 'eyecon' | 'tc' | 'callapp'
| 'hiya' | 'imo' | 'tamtam' | 'phone' | 'botim' | 'luna' | 'taro' | 'web_collection_phone'

export type PsychoProfile = | 'summary' | 'psych'

export type CroloX = 'verify_credentials' | 'get_user_by_id' | 'search_users_x' | 'people_by_query' | 'people_by_geo' | 'list_by_query' | 'list_by_geo' |
'latest_tweets_by_query' | 'latest_tweets_by_geo' | 'top_tweets_by_query' | 'top_tweets_by_geo' | 'tweet_highlights_for_user' | 'tweets_for_user' | 'tweet_media_for_user' |
'tweet_media_by_query' | 'tweet_with_discussion' | 'tweet_media_by_geo' | 'subscriptions_for_user' | 'superfollows_for_user' | 'retweets_for_tweet' | 'followers_for_user'

export type Source = Phone | Social | DeepWeb | WebInt 
| CombinedPhone | CombinedEmail | CombinedNames 
| CombinedId | FaceSearch | SentimentAnalysys | FindClone | KYC | WEB_Collection | IPGeo | IPGeoNumber 
| NameByCountry | SniperAPI  | PhoneList | PsychoProfile | CroloX

interface PhoneData {
  photo?: string | undefined
  avatar?: string
  online?: string
  name?: string
  last_time?: string
  names?: any,
  status?: any,
  displayName?: string,
  emails?: any,
  person?: any,
  possiblePersons?: any,
  sources?: any,
  data?: any,
}

export interface SourcePercentage {
  name: Source
  percentage: number | null
}

interface ResultBase {
  id: number
  requestId: string
  criteria: string
  type: Type
  createdTime: string
  status: Status
  sources: SourcePercentage[]
  percentage?: string
  requestCaption?: string
  
}

export interface PhoneDataItem {
  data: Record<Phone, PhoneData>
  from: Phone
}

interface Education {
  education_level: string
  school_image_url: string
  other_info: string[]
}

export interface LifeEvent {
  [key: number]: string[]
}

interface Work {
  company_name: string
  job_title: string
  other_info: string[]
}

interface LinkedinProfileData {
  'Profile Headline'?: string
}

interface FacebookProfileData {
  education?: Education[]
  life_events?: LifeEvent[]
  work?: Work[]
  places_lived?: {
    current_city: string
    hometown: string
  }
  family_members?: string
  introduction?: string
  relationship?: string
}

export interface NameDataItem {
  from: Social
  profile_id: string
  profileUrl: string
  profileData: LinkedinProfileData & FacebookProfileData
  profileImageUrl: string
  profileName: string
  profileCity: string
  post: string
  createdTime: string
}

export interface NameApiDataItem {
  social_network: string
  profile_id: string
  profileName: string
  profileImageUrl: string
  profile_url : string
  following_count?: number,
  followers_count?: number,
  instagramId? : string,
  instagramProfileLink? : string,
  posts_count: number,
  isPrivate?: boolean,
  isVerified?: boolean,
  created_at?: string,
  location?: string,
  position?: string
  additionalPhoto? : string
  description? : string
}


export interface PostDataItem {
  original_post_user_image: string | undefined
  videos_url: any
  images_url: any
  from: Social
  original_post_user_name: number
  profile_id: string | number
  posted_by_profile_url: string
  text: string
  total_reactions_count: number
  comments_count: number
  post_url: string
  timeStamp: string
  image_url?: string
}

export interface DeepWebDataItem {
  from: Social
  keyword: string,
  customrequestid: number,
  type: string,
  created_date: number,
  address: string,
  country: string,
  date_of_birth: string,
  district: string,
  email: string,
  facebook_id: string,
  facebook_username: string,
  favorite_word: string,
  first_name: string,
  gender: string,
  last_name: string,
  linkedin_id: string,
  linkedin_username: string,
  password: string,
  phone: string,
  profession: string,
  source_name: string,
  ssn: string,
  telegram_id: string,
  telegram_nickname: string,
  telegram_username: string,
  vk_id: string,
}

export interface CombinedPhoneItem {
  from: Social
  keyword: string,
  customrequestid: number,
  type: string,
  created_date: number,
  address: string,
  country: string,
  date_of_birth: string,
  district: string,
  email: string,
  facebook_id: string,
  facebook_username: string,
  favorite_word: string,
  first_name: string,
  gender: string,
  last_name: string,
  linkedin_id: string,
  linkedin_username: string,
  password: string,
  phone: string,
  profession: string,
  source_name: string,
  ssn: string,
  telegram_id: string,
  telegram_nickname: string,
  telegram_username: string,
  vk_id: string,
}

export interface CombinedNameItem {
  from: Social
  profile_id: string
  profileUrl: string
  profileData: LinkedinProfileData & FacebookProfileData
  profileImageUrl: string
  profileName: string
  profileCity: string
  post: string
  createdTime: string
  keyword: string,
  customrequestid: number,
  type: string,
  created_date: number,
  address: string,
  country: string,
  date_of_birth: string,
  district: string,
  email: string,
  facebook_id: string,
  facebook_username: string,
  favorite_word: string,
  first_name: string,
  gender: string,
  last_name: string,
  linkedin_id: string,
  linkedin_username: string,
  password: string,
  phone: string,
  profession: string,
  source_name: string,
  ssn: string,
  telegram_id: string,
  telegram_nickname: string,
  telegram_username: string,
  vk_id: string,
}

export interface CombinedIdItem {
  from: Social
  profile_id: string
  profileUrl: string
  profileData: LinkedinProfileData & FacebookProfileData
  profileImageUrl: string
  profileName: string
  profileCity: string
  post: string
  createdTime: string
  keyword: string,
  customrequestid: number,
  type: string,
  created_date: number,
  address: string,
  country: string,
  date_of_birth: string,
  district: string,
  email: string,
  facebook_id: string,
  facebook_username: string,
  favorite_word: string,
  first_name: string,
  gender: string,
  last_name: string,
  linkedin_id: string,
  linkedin_username: string,
  password: string,
  phone: string,
  profession: string,
  source_name: string,
  ssn: string,
  telegram_id: string,
  telegram_nickname: string,
  telegram_username: string,
  vk_id: string,
}

export interface FaceSearchIdItem {
  original_post_user_image: string | undefined,
  age: number,
  bio: string,
  born: string,

  city: string,
  country: string,

  face: string,
  face_db: string,
  filtered: number,
  first_name: string,
  last_name: string,
  maiden_name: string,
  photo: string,
  photo_height: number,
  photo_id: string,
  photo_width: number,
  photo_x: number,
  photo_y: number,
  profile: string,
  score: string,
  source: string,
  user_id: string,
  network?: string,
  network_icon?: string,
  details?: any[],
}

export interface SentimentAnalysysItem {
  text : string,
  sentiment: string,
  topic: string,
  summary: string
  entity: string,
  status: string,
  createdTime: string,
}

export interface KYCItem {
  country: string
  domain: string
  employees_count: number
  industry: string
  linkedin_url: string
  locality : string
  name: string
  year_founded: number
  iban: string
  is_valid: boolean
  vat_number: string,
  vat_valid: boolean,
  company_name: string,
  comapany_address: string,
  company_country_code: string,
  company_country_name: string,
  source_type: string
}

export interface IPGeolocationItem {
  ip_address: string
  city: string
  city_geoname_id: number
  region: string
  region_geoname_id: number
  region_iso_code: string
  postal_code: number
  country: string
  country_code: string
  country_geoname_id: number
  country_is_eu: boolean
  continent: string
  continent_code: string
  continent_geoname_id: number
  latitude: number
  longitude: number
  is_vpn: boolean
}

export interface PsychoAnalysysItem {
  image? : string,
  levelOfDanger? : string,
  personName? : string,
  predictedCharacteristics? : string[],
  psychologicalPortrait? : string,
  age? : string,
  country? : string,
  gender?: string,
  occupation? : string,
  summary? : string
}

export interface IpPhoneGeolocationItem {
  phone?: string | undefined,
  phoneCountry?: string | undefined,
  phoneCountryCode?: string | undefined,
  ip?: string | undefined,
  ipCountry?: string | undefined,
  ipCountryCode?: string | undefined,
  status?: boolean | undefined
}

export interface WebCollectionItem {
  image : string
  htmlCode : string
  source_type: string
}

interface findCloneDetails {
  photoid: number,
  size: number,
  url: string,
  userid: number,
  x: number,
  y: number
}

export interface FindCloneItem {
  city : string,
  details : findCloneDetails[],
  firstname: string,
  item_id : number,
  score : number,
  thumbnail : string
}

export interface PhoneLookupResult extends ResultBase {
  from?: string
  data?: PhoneDataItem[]
}

export interface NameLookupResult extends ResultBase {
  data?: NameDataItem[]
}

export interface NameApiLookupResult extends ResultBase {
  data?: NameApiDataItem[]
}

export interface PostLookupResult extends ResultBase {
  data?: PostDataItem[]
}

export interface DeepWebLookupResult extends ResultBase {
  data?: DeepWebDataItem[]
}

export interface CombinedPhoneResult extends ResultBase {
  data?: CombinedPhoneItem[]
}

export interface CombinedNameResult extends ResultBase {
  data?: CombinedNameItem[]
}

export interface CombinedIdResult extends ResultBase {
  data?: CombinedIdItem[]
}

export interface FaceSearchResult extends ResultBase {
  data?: FaceSearchIdItem[]
}

export interface SentimentAnalysysResult extends ResultBase {
  data?: SentimentAnalysysItem[]
}

export interface KYCResult extends ResultBase {
  data?: KYCItem[]
}

export interface IPGeolocationResult extends ResultBase {
  data?: IPGeolocationItem[]
}

export interface IPsychoAnalysysResult extends ResultBase {
  data?: PsychoAnalysysItem[]
}

export interface IpPhoneGeolocationResult extends ResultBase {
  data?: IpPhoneGeolocationItem[]
}

export interface WebCollectionResult extends ResultBase {
  data?: WebCollectionItem[]
}

export interface FindCloneResult extends ResultBase {
  data?: FindCloneItem[]
}

export type SearchResult =
  | PhoneLookupResult
  | NameLookupResult
  | NameApiLookupResult
  | PostLookupResult
  | DeepWebLookupResult
  | CombinedPhoneResult
  | CombinedNameResult
  | CombinedIdResult
  | FaceSearchResult
  | SentimentAnalysysResult
  | FindCloneResult
  | KYCResult
  | IPGeolocationResult
  | WebCollectionResult

export interface ILookup {
  id: number
  type: Type
  title: string
  price: number
  fakeCheckPrice?: number 
  validateNames : any[]
  sources: Source[]
  subTitle: string
  input: string
  button: string
  color: string
  country: string
}

export type SearcherState = {
  lookups: ILookup[] | null
  resultList: SearchResult[]
  viewResult: SearchResult | null
  page: number
  pdfData: any
}

const initialState: SearcherState = {
  lookups: null,
  resultList: [],
  viewResult: null,
  page: 1,
  pdfData: null,
}

export function searcherReducer(state = initialState, action: SearcherAction) {
  switch (action.type) {
    case 'searcher/SET_LOOKUP':
      return {
        ...state,
        lookups: action.data,
      }
    case 'searcher/TOGGLE_VIEW_RESULT':
      return {
        ...state,
        viewResult: action.data,
      }
    case 'searcher/SET_PAGE':
      return {
        ...state,
        page: action.number,
      }
    case 'searcher/SET_PDF_DATA':
      return {
        ...state,
        pdfData: action.pdfData,
      }
    default:
      return state
  }
}
