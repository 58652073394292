// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".settings_defaultAvatar__366bX {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-bottom: 2rem;\n  width: 200px;\n  height: 200px;\n  background: #EFF0F6;\n}\n.settings_defaultAvatar__366bX svg {\n  width: 100px;\n  height: 100px;\n  color: #D6D7E3;\n}", "",{"version":3,"sources":["webpack://src/sass/settings.module.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;AACF;AACE;EACE,YAAA;EACA,aAAA;EACA,cAAA;AACJ","sourcesContent":[".defaultAvatar\n  display: flex\n  justify-content: center\n  align-items: center\n  margin-bottom: 2rem\n  width: 200px\n  height: 200px\n  background: #EFF0F6\n\n  svg\n    width: 100px\n    height: 100px\n    color: #D6D7E3\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"defaultAvatar": "settings_defaultAvatar__366bX"
};
export default ___CSS_LOADER_EXPORT___;
