// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".content_root__2B4hD, .content_root_narrow__3Bx-z.content_root_rtl__2Dte2, .content_root_narrow__3Bx-z.content_root_ltr__1lFsM, .content_root_wide__3xokM.content_root_rtl__2Dte2, .content_root_wide__3xokM.content_root_ltr__1lFsM, .content_root_mobile__AAk6r.content_root_rtl__2Dte2, .content_root_mobile__AAk6r.content_root_ltr__1lFsM {\n  min-height: calc(100vh - 86px);\n  transition: margin-left 0.3s ease;\n  box-sizing: border-box;\n}\n.content_root_ltr__1lFsM {\n  margin-left: auto;\n}\n.content_root_rtl__2Dte2 {\n  margin-right: auto;\n}\n\n@media (min-width: 900px) {\n  .content_root_wide__3xokM.content_root_ltr__1lFsM {\n    margin-left: 64px;\n  }\n}\n\n.content_root_wide__3xokM.content_root_rtl__2Dte2 {\n  margin-right: 64px;\n}\n\n.content_root_narrow__3Bx-z.content_root_ltr__1lFsM {\n  margin-left: 260px;\n}\n\n.content_root_narrow__3Bx-z.content_root_rtl__2Dte2 {\n  margin-right: 240px;\n}", "",{"version":3,"sources":["webpack://src/sass/content.module.sass"],"names":[],"mappings":"AACA;EACE,8BAAA;EACA,iCAAA;EACA,sBAAA;AAAF;AAEE;EACE,iBAAA;AAAJ;AAEE;EACE,kBAAA;AAAJ;;AAgBE;EAHF;IAII,iBAAA;EAZF;AACF;;AAaA;EAEE,kBAAA;AAXF;;AAaA;EAEE,kBAAA;AAXF;;AAaA;EAEE,mBAAA;AAXF","sourcesContent":["@import \"variable\"\n.root\n  min-height: calc(100vh - 86px)\n  transition: margin-left 0.3s ease\n  box-sizing: border-box\n\n  &_ltr\n    margin-left: auto\n\n  &_rtl\n    margin-right: auto\n\n\n.root_mobile.root_ltr\n  @extend .root\n\n  //margin-left: 64px\n\n.root_mobile.root_rtl\n  @extend .root\n\n  //margin-right: 60px\n\n.root_wide.root_ltr\n  @extend .root\n\n  @media (min-width: $breakpointMd)\n    margin-left: 64px\n\n.root_wide.root_rtl\n  @extend .root\n  margin-right: 64px\n\n.root_narrow.root_ltr\n  @extend .root\n  margin-left: 260px\n\n.root_narrow.root_rtl\n  @extend .root\n  margin-right: 240px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "content_root__2B4hD",
	"root_narrow": "content_root_narrow__3Bx-z",
	"root_rtl": "content_root_rtl__2Dte2",
	"root_ltr": "content_root_ltr__1lFsM",
	"root_wide": "content_root_wide__3xokM",
	"root_mobile": "content_root_mobile__AAk6r"
};
export default ___CSS_LOADER_EXPORT___;
