import * as React from 'react'
import { t, TFunctionResult } from 'i18next'
import {
  Box,
  Button,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { PopupRequestCustomPackage } from './PopupRequestCustomPackage'
import { useSelector } from '../store'
import {
  IFeature,
  IPackage,
  IPackageCustomized,
} from '../store/package/reducer'
import iconSet from '../asset/sprite/package_icons.png'
import iconProSet from '../asset/sprite/pro_icons.png'
import iconProfilerLookup from '../asset/sprite/quota_lookups.png'
import iconProfilerCrawlers from '../asset/sprite/quota_sn.png'
import iconProfilerCases from '../asset/sprite/quota_cases.png'
import iconWebint from '../asset/sprite/quota_webint.png'
import iconWebintMonitoring from '../asset/sprite/quota_monitoring.png'
import iconWebintCases from '../asset/sprite/quota_webint_cases.png'

import { StyledPackage } from '../style/package'

function getDuration(days: number, isAnnual : boolean, data : any): TFunctionResult {
  const months = [28, 29, 30, 31]
  if (data && data.type && data.type === 'one_time_fee') {
    return 'One Time Fee'
  }
  if (data && data.price && isAnnual && data.annualPrice && data.annualProductId) {
    return `per month`
  }
  if (months.includes(days)) {
    return t('package.duration.perMonth')
  } else {
    return `${t('package.duration.per')} ${days} ${t('package.duration.days')}`
  }
}

interface Props {
  data: IPackage | IPackageCustomized
  packagesQuantity: number
  setPurchaseOpen?: (data: IPackage) => void
  isAnnual : boolean,
  webintActive?: boolean,
  isBought?: boolean,
}

export function PackageItem({
  data,
  packagesQuantity,
  setPurchaseOpen,
  isAnnual,
  webintActive = false,
  isBought = false,
}: Props) {

  //selectors
  const { userPackage, packageBalance } = useSelector((state) => state.settings)
  const { uid } = useSelector((state) => state.settings)
  //const { quota} = useSelector((state) => state.userQuota)

  //states
  const [customOpen, setCustomOpen] = React.useState(false)

  let productId = data.productId
  let productPrice = data.price

  if ( isAnnual && data.annualProductId && data.annualPrice) {
    productId = data.annualProductId
    productPrice = data.annualPrice
  } 

  const iframeUrl = React.useMemo(() => {
    const url = new URL(`https://store.payproglobal.com/checkout`)
    url.searchParams.set('exfo', '742')

    url.searchParams.set('products[1][id]', String(productId))

    url.searchParams.set('products[1][Name]', String(data.title))
    url.searchParams.set('products[1][Price][EUR][amount]', String(productPrice))
    url.searchParams.set(
      'currency',
      String(process.env.REACT_APP_PAYPRO_CURRENCY),
    )
    url.searchParams.set('x-uid', String(uid))
    url.searchParams.set('x-packageId', String(data.id))
    if (data.type == 'webinthub') {
      url.searchParams.set('x-packageType', 'webinthub')
    }
    if (data.type == 'webinthub_subpackage') {
      url.searchParams.set('x-packageType', 'webinthub_subpackage')
    }
    if (data.type == 'webinthub_upgrade') {
      url.searchParams.set('x-packageType', 'webinthub_upgrade')
    }
    if (process.env.REACT_APP_PAYPRO_TEST_MODE === 'true')
      url.searchParams.set('use-test-mode', 'true')
    if (process.env.REACT_APP_PAYPRO_TEST_MODE === 'true')
      url.searchParams.set(
        'secret-key',
        String(process.env.REACT_APP_PAYPRO_TEST_SECRET),
      )

    return url.href
  }, [productId, productPrice, data, uid])

  const isActive = React.useMemo(() => {
    return userPackage?.packageId === data.id
  }, [userPackage, data])

  const isCustom = data.type === 'custom'
  const isWebintHub = data.type === 'webinthub'
  const isSubWebintHub = data.type === 'webinthub_subpackage'
  const isWebintHubUpgrade = data.type == 'webinthub_upgrade'
  const isWebintHubDemo = data.type === 'webinthub_demo'
  const mode = (() => {
    //if (isActive) return 'active'
    if (isCustom) return 'custom'
    if (isWebintHubUpgrade) return 'advanced'
    if (isSubWebintHub) return 'pro'
    if (isWebintHub) return 'pro'
    return ''
  })()

  const isDisabled = 
  data.price < (userPackage?.price || 0) && packageBalance !== 0 && !isWebintHubDemo && !isCustom && !isWebintHub && !isSubWebintHub && !isWebintHubUpgrade && !isCustom

  function handleBuyClick() {
    if (isDisabled) return
    window.open(iframeUrl, '_blank')
  }

  if (data.isFree && packageBalance && packageBalance <= 0) {
    return null
  }

  const disableDisplay: string[] = ['quotaprofiler-periodic','quotaprofiler-monitoring','quotawebint-lookup','quotawebint-periodic']

  const checkPrice = ( isAnnual : boolean, data : any) => {
    return data && data.price && isAnnual && data.annualPrice && data.annualProductId ?
    (data.annualPrice / 12).toFixed(0):
    data.price
  }

  const checkQuota = (isAnnual: boolean, quota: any) => {
    return isAnnual && (!webintActive || isBought) ? quota * 12 : quota
  }

  const checkCredits = ( isAnnual : boolean, data : any) => {
    if (data.type !== 'general' && data.type !== 'personal') {
      return
    }
    if (isAnnual && data.creditsPerUnit && data.annualPrice  && data.annualProductId) {
      //return <><Typography className={'package__credits_price'}>{Math.round((data.price * data.creditsPerUnit)  +  ( (data.price * 12 - data.annualPrice) / 12) * data.creditsPerUnit)}</Typography>credits</>
      return <><Typography className={'package__credits_price'}>{Math.round((data.price * data.creditsPerUnit * 12))}</Typography>credits</>
    }
    if ((!isAnnual && data.creditsPerUnit) || (isAnnual && data.creditsPerUnit && !(data.annualPrice  && data.annualProductId))) {
      return <><Typography className={'package__credits_price'}>{(data.price * data.creditsPerUnit)}</Typography>credits</>
    }
  }
  
  const checkDiscount = (isAnnual : boolean, data : any ) => {
    if (isAnnual && data.creditsPerUnit && data.annualPrice) {
      //return Math.round(((data.price * 12 - data.annualPrice) / 12) * data.creditsPerUnit * 12) + " bonus credits"
    } else 
    //return `${Math.round(   (((data.price * 12) - data.annualPrice) / (data.price * 12)) * 100 )  }% discount`
    return `-${Math.round(   (((data.price * 12) - data.annualPrice) / (data.price * 12)) * 100 )  }%`
  }

  return (
    <StyledPackage {...{disabled: isDisabled, mode: mode, isActive: isActive, isAnnual: isAnnual, isBought: isBought}}>
      <Stack className={'package__badge_container'}>
        {
          isAnnual && data.annualPrice && <Box className={'package__badge_active'}>{checkDiscount(isAnnual, data)}</Box>
        }
        {
          isActive && <Box className={'package__badge_active'}>Active</Box>
        }
      </Stack>
      {
        (mode == 'pro' || mode === 'advanced' || mode === 'custom') && <Box className={'package__pro_kind_container'}>
          {
            mode === 'pro' && <Box className={'package__pro_kind_badge package__pro_kind_badge_pro'}>Irbis PRO</Box>
          }
          {
            mode === 'advanced' && <Box className={'package__pro_kind_badge package__pro_kind_badge_pro'}>Irbis Advanced</Box>
          }
          {
            mode === 'custom' && <Box className={'package__pro_kind_badge package__pro_kind_badge_custom'}>Enterprise</Box>
          }
        </Box>
      }
      {
        mode === 'custom'
          ? <Stack className={'package__header_custom'} direction={'column'} gap={'25px'}>
            <Box className={'package__badge_custom'}>Monthly/Annual</Box>
            <Typography className={'package__title_custom'}>custom</Typography>
            <Button
             variant="contained"
            onClick={(e) => {
              window.open( ( data.feature1 as any ).demoLink ? ( data.feature1 as any ).demoLink : 'https://api-docs.espysys.com/', '_blank')
            }}
          >Book a Demo</Button>
          </Stack>
          : <Stack className={'package__header'} direction={'row'}>
            <Box sx={{position: 'relative'}}>
              <Typography className={'package__currency'}>€</Typography>
              <Typography className={'package__price'}>{checkPrice(isAnnual, data)}</Typography>
              {
                isAnnual && data.annualPrice && <Typography className={'package__price_old'}>€{checkPrice(false, data)}</Typography>
              }
            </Box>
            <Box className={'package__divider'} />
            <Stack direction={'column'} className={'package__name'}>
              <Tooltip title={data.title}><Typography noWrap={true} className={'package__title'}>{data.title}</Typography></Tooltip>
              <Typography className={'package__title package__subtitle'}>{getDuration(data.activeDay, isAnnual, data)}</Typography>
              {
                (((!webintActive && (mode === 'pro' || mode === 'advanced')) || mode === '') && (data.type && data.type !== 'one_time_fee' )) && <Typography className={'package__title package__annual_info'}>billed {isAnnual ? 'annually' : 'monthly'}</Typography>
              }
            </Stack>
          </Stack>
      }
      {
        mode === '' && <Stack className={'package__credits'}>
          {checkCredits(isAnnual, data)}&nbsp;
        </Stack>
      }
      <Stack className={'package__icons'}>
        {
          //(mode === 'pro' || mode === 'advanced') && <img src={iconProSet} alt={'Sources'} />
          (mode === 'pro' || mode === 'advanced') && <Stack className={'package__pro_icons_container'}>
            <Grid container spacing={1}>
              <Grid item xs={4} className={'package__pro_icons_item'}>
                <img src={iconProfilerLookup} alt='' />
                <Typography className={'package__pro_icon'}>{checkQuota(isAnnual, webintActive && !isBought ? data.packageQuotas.additionalProfilerLookup : data.packageQuotas.quotaProfilerLookup)}</Typography>
              </Grid>
              <Grid item xs={4} className={'package__pro_icons_item'}>
                <img src={iconProfilerCrawlers} alt='' />
                <Typography className={'package__pro_icon'}>{checkQuota(isAnnual, webintActive && !isBought ? data.packageQuotas.additionalProfilerCrawlers : data.packageQuotas.quotaProfilerCrawlers)}</Typography>
              </Grid>
              <Grid item xs={4} className={'package__pro_icons_item'}>
                <img src={iconProfilerCases} alt='' />
                <Typography className={'package__pro_icon'}>{checkQuota(isAnnual, webintActive && !isBought ? data.packageQuotas.additionalProfilerCases : data.packageQuotas.quotaProfilerCases)}</Typography>
              </Grid>
              <Grid item xs={4} className={'package__pro_icons_item'}>
                <img src={iconWebint} alt='' />
                <Typography className={'package__pro_icon'}>{checkQuota(isAnnual, webintActive && !isBought ? data.packageQuotas.additionalWebIntCrawlers : data.packageQuotas.quotaWebIntCrawlers)}</Typography>
              </Grid>
              <Grid item xs={4} className={'package__pro_icons_item'}>
                <img src={iconWebintMonitoring} alt='' />
                <Typography className={'package__pro_icon'}>{checkQuota(isAnnual, webintActive && !isBought ? data.packageQuotas.additionalWebIntMonitoring : data.packageQuotas.quotaWebIntMonitoring)}</Typography>
              </Grid>
              <Grid item xs={4} className={'package__pro_icons_item'}>
                <img src={iconWebintCases} alt='' />
                <Typography className={'package__pro_icon'}>{checkQuota(isAnnual, webintActive && !isBought ? data.packageQuotas.additionalWebIntCases: data.packageQuotas.quotaWebIntCases)}</Typography>
              </Grid>
            </Grid>
          </Stack>
        }
        {
          mode === '' && <img src={iconSet} alt={'Sources'} />
        }
      </Stack>
      <Stack className={'package__features_container'}>
        <ul className={'package__features'}>
          {
            data.feature1.title && <li>
              <Typography className={'package__features_title'}>{data.feature1.title}</Typography>
              <Typography className={'package__features_subtitle'}>{data.feature1.subTitle}</Typography>
            </li>
          }
          {
            data.feature2.title && <li>
              <Typography className={'package__features_title'}>{data.feature2.title}</Typography>
              <Typography className={'package__features_subtitle'}>{data.feature2.subTitle}</Typography>
            </li>
          }
          {
            data.feature3.title && <li>
              <Typography className={'package__features_title'}>{data.feature3.title}</Typography>
              <Typography className={'package__features_subtitle'}>{data.feature3.subTitle}</Typography>
            </li>
          }
        </ul>
      </Stack>
      {
        !(data.isFree || isBought) && <Stack>
          <Button
            size="large"
            variant="contained"
            className={'package__buy'}
            disabled={isDisabled}
            onClick={isCustom ? () => setCustomOpen(true) : handleBuyClick}
          >{data.button}</Button>
        </Stack>
      }
      {customOpen && (
        <PopupRequestCustomPackage onClose={() => setCustomOpen(false)} />
      )}
    </StyledPackage>
  )
}
